@charset "UTF-8";
/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.bootstrap-styles {
  /* rtl:raw:
  [type="tel"],
  [type="url"],
  [type="email"],
  [type="number"] {
    direction: ltr;
  }
  */
  /* rtl:begin:remove */
  /* rtl:end:remove */
}
.bootstrap-styles :root,
.bootstrap-styles [data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}
.bootstrap-styles [data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}
.bootstrap-styles *,
.bootstrap-styles *::before,
.bootstrap-styles *::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  .bootstrap-styles :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.bootstrap-styles hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}
.bootstrap-styles h6, .bootstrap-styles .h6, .bootstrap-styles h5, .bootstrap-styles .h5, .bootstrap-styles h4, .bootstrap-styles .h4, .bootstrap-styles h3, .bootstrap-styles .h3, .bootstrap-styles h2, .bootstrap-styles .h2, .bootstrap-styles h1, .bootstrap-styles .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}
.bootstrap-styles h1, .bootstrap-styles .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .bootstrap-styles h1, .bootstrap-styles .h1 {
    font-size: 2.5rem;
  }
}
.bootstrap-styles h2, .bootstrap-styles .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .bootstrap-styles h2, .bootstrap-styles .h2 {
    font-size: 2rem;
  }
}
.bootstrap-styles h3, .bootstrap-styles .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .bootstrap-styles h3, .bootstrap-styles .h3 {
    font-size: 1.75rem;
  }
}
.bootstrap-styles h4, .bootstrap-styles .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .bootstrap-styles h4, .bootstrap-styles .h4 {
    font-size: 1.5rem;
  }
}
.bootstrap-styles h5, .bootstrap-styles .h5 {
  font-size: 1.25rem;
}
.bootstrap-styles h6, .bootstrap-styles .h6 {
  font-size: 1rem;
}
.bootstrap-styles p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.bootstrap-styles abbr[title] {
  -webkit-text-decoration: underline dotted;
  -moz-text-decoration: underline dotted;
       text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
.bootstrap-styles address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
.bootstrap-styles ol,
.bootstrap-styles ul {
  padding-left: 2rem;
}
.bootstrap-styles ol,
.bootstrap-styles ul,
.bootstrap-styles dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
.bootstrap-styles ol ol,
.bootstrap-styles ul ul,
.bootstrap-styles ol ul,
.bootstrap-styles ul ol {
  margin-bottom: 0;
}
.bootstrap-styles dt {
  font-weight: 700;
}
.bootstrap-styles dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.bootstrap-styles blockquote {
  margin: 0 0 1rem;
}
.bootstrap-styles b,
.bootstrap-styles strong {
  font-weight: bolder;
}
.bootstrap-styles small, .bootstrap-styles .small {
  font-size: 0.875em;
}
.bootstrap-styles mark, .bootstrap-styles .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}
.bootstrap-styles sub,
.bootstrap-styles sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
.bootstrap-styles sub {
  bottom: -0.25em;
}
.bootstrap-styles sup {
  top: -0.5em;
}
.bootstrap-styles a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
.bootstrap-styles a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}
.bootstrap-styles a:not([href]):not([class]), .bootstrap-styles a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
.bootstrap-styles pre,
.bootstrap-styles code,
.bootstrap-styles kbd,
.bootstrap-styles samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}
.bootstrap-styles pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
.bootstrap-styles pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.bootstrap-styles code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > .bootstrap-styles code {
  color: inherit;
}
.bootstrap-styles kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
.bootstrap-styles kbd kbd {
  padding: 0;
  font-size: 1em;
}
.bootstrap-styles figure {
  margin: 0 0 1rem;
}
.bootstrap-styles img,
.bootstrap-styles svg {
  vertical-align: middle;
}
.bootstrap-styles table {
  caption-side: bottom;
  border-collapse: collapse;
}
.bootstrap-styles caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}
.bootstrap-styles th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
.bootstrap-styles thead,
.bootstrap-styles tbody,
.bootstrap-styles tfoot,
.bootstrap-styles tr,
.bootstrap-styles td,
.bootstrap-styles th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
.bootstrap-styles label {
  display: inline-block;
}
.bootstrap-styles button {
  border-radius: 0;
}
.bootstrap-styles button:focus:not(:focus-visible) {
  outline: 0;
}
.bootstrap-styles input,
.bootstrap-styles button,
.bootstrap-styles select,
.bootstrap-styles optgroup,
.bootstrap-styles textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.bootstrap-styles button,
.bootstrap-styles select {
  text-transform: none;
}
.bootstrap-styles [role=button] {
  cursor: pointer;
}
.bootstrap-styles select {
  word-wrap: normal;
}
.bootstrap-styles select:disabled {
  opacity: 1;
}
.bootstrap-styles [list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}
.bootstrap-styles button,
.bootstrap-styles [type=button],
.bootstrap-styles [type=reset],
.bootstrap-styles [type=submit] {
  -webkit-appearance: button;
}
.bootstrap-styles button:not(:disabled),
.bootstrap-styles [type=button]:not(:disabled),
.bootstrap-styles [type=reset]:not(:disabled),
.bootstrap-styles [type=submit]:not(:disabled) {
  cursor: pointer;
}
.bootstrap-styles ::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.bootstrap-styles textarea {
  resize: vertical;
}
.bootstrap-styles fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.bootstrap-styles legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  .bootstrap-styles legend {
    font-size: 1.5rem;
  }
}
.bootstrap-styles legend + * {
  clear: left;
}
.bootstrap-styles ::-webkit-datetime-edit-fields-wrapper,
.bootstrap-styles ::-webkit-datetime-edit-text,
.bootstrap-styles ::-webkit-datetime-edit-minute,
.bootstrap-styles ::-webkit-datetime-edit-hour-field,
.bootstrap-styles ::-webkit-datetime-edit-day-field,
.bootstrap-styles ::-webkit-datetime-edit-month-field,
.bootstrap-styles ::-webkit-datetime-edit-year-field {
  padding: 0;
}
.bootstrap-styles ::-webkit-inner-spin-button {
  height: auto;
}
.bootstrap-styles [type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
.bootstrap-styles ::-webkit-search-decoration {
  -webkit-appearance: none;
}
.bootstrap-styles ::-webkit-color-swatch-wrapper {
  padding: 0;
}
.bootstrap-styles ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
.bootstrap-styles ::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}
.bootstrap-styles output {
  display: inline-block;
}
.bootstrap-styles iframe {
  border: 0;
}
.bootstrap-styles summary {
  display: list-item;
  cursor: pointer;
}
.bootstrap-styles progress {
  vertical-align: baseline;
}
.bootstrap-styles [hidden] {
  display: none !important;
}
.bootstrap-styles .lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.bootstrap-styles .display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .bootstrap-styles .display-1 {
    font-size: 5rem;
  }
}
.bootstrap-styles .display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .bootstrap-styles .display-2 {
    font-size: 4.5rem;
  }
}
.bootstrap-styles .display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .bootstrap-styles .display-3 {
    font-size: 4rem;
  }
}
.bootstrap-styles .display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .bootstrap-styles .display-4 {
    font-size: 3.5rem;
  }
}
.bootstrap-styles .display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .bootstrap-styles .display-5 {
    font-size: 3rem;
  }
}
.bootstrap-styles .display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .bootstrap-styles .display-6 {
    font-size: 2.5rem;
  }
}
.bootstrap-styles .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.bootstrap-styles .list-inline {
  padding-left: 0;
  list-style: none;
}
.bootstrap-styles .list-inline-item {
  display: inline-block;
}
.bootstrap-styles .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.bootstrap-styles .initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.bootstrap-styles .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.bootstrap-styles .blockquote > :last-child {
  margin-bottom: 0;
}
.bootstrap-styles .blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.bootstrap-styles .blockquote-footer::before {
  content: "— ";
}
.bootstrap-styles .img-fluid {
  max-width: 100%;
  height: auto;
}
.bootstrap-styles .img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}
.bootstrap-styles .figure {
  display: inline-block;
}
.bootstrap-styles .figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.bootstrap-styles .figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}
.bootstrap-styles .container,
.bootstrap-styles .container-fluid,
.bootstrap-styles .container-xxl,
.bootstrap-styles .container-xl,
.bootstrap-styles .container-lg,
.bootstrap-styles .container-md,
.bootstrap-styles .container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
/* @media (min-width: 576px) {
  .bootstrap-styles .container-sm, .bootstrap-styles .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .bootstrap-styles .container-md, .bootstrap-styles .container-sm, .bootstrap-styles .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .bootstrap-styles .container-lg, .bootstrap-styles .container-md, .bootstrap-styles .container-sm, .bootstrap-styles .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .bootstrap-styles .container-xl, .bootstrap-styles .container-lg, .bootstrap-styles .container-md, .bootstrap-styles .container-sm, .bootstrap-styles .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .bootstrap-styles .container-xxl, .bootstrap-styles .container-xl, .bootstrap-styles .container-lg, .bootstrap-styles .container-md, .bootstrap-styles .container-sm, .bootstrap-styles .container {
    max-width: 1320px;
  } */
/* } */
.bootstrap-styles :root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}
.bootstrap-styles .row-bootstrap {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.bootstrap-styles .row-bootstrap > * {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.row-bootstrap::after, .row-bootstrap::before {
  content: " ";
  display: table;
} 
.row-bootstrap::after {
  clear: both;
}
.bootstrap-styles .col {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 0%;
      -ms-flex: 1 0 0%;
          flex: 1 0 0%;
}
.bootstrap-styles .row-cols-auto > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}
.bootstrap-styles .row-cols-1 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 100%;
}
.bootstrap-styles .row-cols-2 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 50%;
}
.bootstrap-styles .row-cols-3 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 33.33333333%;
}
.bootstrap-styles .row-cols-4 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 25%;
}
.bootstrap-styles .row-cols-5 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 20%;
}
.bootstrap-styles .row-cols-6 > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 16.66666667%;
}
.bootstrap-styles .col-auto {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}
.bootstrap-styles .col-1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 8.33333333%;
}
.bootstrap-styles .col-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 16.66666667%;
}
.bootstrap-styles .col-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 25%;
}
.bootstrap-styles .col-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 33.33333333%;
}
.bootstrap-styles .col-5 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 41.66666667%;
}
.bootstrap-styles .col-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 50%;
}
.bootstrap-styles .col-7 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 58.33333333%;
}
.bootstrap-styles .col-8 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 66.66666667%;
}
.bootstrap-styles .col-9 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 75%;
}
.bootstrap-styles .col-10 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 83.33333333%;
}
.bootstrap-styles .col-11 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 91.66666667%;
}
.bootstrap-styles .col-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 100%;
}
.bootstrap-styles .offset-1 {
  margin-left: 8.33333333%;
}
.bootstrap-styles .offset-2 {
  margin-left: 16.66666667%;
}
.bootstrap-styles .offset-3 {
  margin-left: 25%;
}
.bootstrap-styles .offset-4 {
  margin-left: 33.33333333%;
}
.bootstrap-styles .offset-5 {
  margin-left: 41.66666667%;
}
.bootstrap-styles .offset-6 {
  margin-left: 50%;
}
.bootstrap-styles .offset-7 {
  margin-left: 58.33333333%;
}
.bootstrap-styles .offset-8 {
  margin-left: 66.66666667%;
}
.bootstrap-styles .offset-9 {
  margin-left: 75%;
}
.bootstrap-styles .offset-10 {
  margin-left: 83.33333333%;
}
.bootstrap-styles .offset-11 {
  margin-left: 91.66666667%;
}
.bootstrap-styles .g-0,
.bootstrap-styles .gx-0 {
  --bs-gutter-x: 0;
}
.bootstrap-styles .g-0,
.bootstrap-styles .gy-0 {
  --bs-gutter-y: 0;
}
.bootstrap-styles .g-1,
.bootstrap-styles .gx-1 {
  --bs-gutter-x: 0.25rem;
}
.bootstrap-styles .g-1,
.bootstrap-styles .gy-1 {
  --bs-gutter-y: 0.25rem;
}
.bootstrap-styles .g-2,
.bootstrap-styles .gx-2 {
  --bs-gutter-x: 0.5rem;
}
.bootstrap-styles .g-2,
.bootstrap-styles .gy-2 {
  --bs-gutter-y: 0.5rem;
}
.bootstrap-styles .g-3,
.bootstrap-styles .gx-3 {
  --bs-gutter-x: 1rem;
}
.bootstrap-styles .g-3,
.bootstrap-styles .gy-3 {
  --bs-gutter-y: 1rem;
}
.bootstrap-styles .g-4,
.bootstrap-styles .gx-4 {
  --bs-gutter-x: 1.5rem;
}
.bootstrap-styles .g-4,
.bootstrap-styles .gy-4 {
  --bs-gutter-y: 1.5rem;
}
.bootstrap-styles .g-5,
.bootstrap-styles .gx-5 {
  --bs-gutter-x: 3rem;
}
.bootstrap-styles .g-5,
.bootstrap-styles .gy-5 {
  --bs-gutter-y: 3rem;
}
@media (min-width: 576px) {
  .bootstrap-styles .col-sm {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 0%;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .bootstrap-styles .row-cols-sm-auto > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .bootstrap-styles .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap-styles .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap-styles .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap-styles .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap-styles .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .bootstrap-styles .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap-styles .col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
 
  .bootstrap-styles .offset-sm-0 {
    margin-left: 0;
  }
  .bootstrap-styles .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .bootstrap-styles .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .bootstrap-styles .offset-sm-3 {
    margin-left: 25%;
  }
  .bootstrap-styles .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .bootstrap-styles .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .bootstrap-styles .offset-sm-6 {
    margin-left: 50%;
  }
  .bootstrap-styles .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .bootstrap-styles .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .bootstrap-styles .offset-sm-9 {
    margin-left: 75%;
  }
  .bootstrap-styles .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .bootstrap-styles .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .bootstrap-styles .g-sm-0,
  .bootstrap-styles .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .bootstrap-styles .g-sm-0,
  .bootstrap-styles .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .bootstrap-styles .g-sm-1,
  .bootstrap-styles .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .bootstrap-styles .g-sm-1,
  .bootstrap-styles .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .bootstrap-styles .g-sm-2,
  .bootstrap-styles .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .bootstrap-styles .g-sm-2,
  .bootstrap-styles .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .bootstrap-styles .g-sm-3,
  .bootstrap-styles .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .bootstrap-styles .g-sm-3,
  .bootstrap-styles .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .bootstrap-styles .g-sm-4,
  .bootstrap-styles .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .bootstrap-styles .g-sm-4,
  .bootstrap-styles .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .bootstrap-styles .g-sm-5,
  .bootstrap-styles .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .bootstrap-styles .g-sm-5,
  .bootstrap-styles .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .bootstrap-styles .col-md {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 0%;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .bootstrap-styles .row-cols-md-auto > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .bootstrap-styles .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap-styles .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap-styles .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap-styles .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap-styles .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .bootstrap-styles .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap-styles .col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .bootstrap-styles .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .bootstrap-styles .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap-styles .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap-styles .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap-styles .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .bootstrap-styles .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap-styles .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .bootstrap-styles .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .bootstrap-styles .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .bootstrap-styles .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .bootstrap-styles .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .bootstrap-styles .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap-styles .offset-md-0 {
    margin-left: 0;
  }
  .bootstrap-styles .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .bootstrap-styles .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .bootstrap-styles .offset-md-3 {
    margin-left: 25%;
  }
  .bootstrap-styles .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .bootstrap-styles .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .bootstrap-styles .offset-md-6 {
    margin-left: 50%;
  }
  .bootstrap-styles .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .bootstrap-styles .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .bootstrap-styles .offset-md-9 {
    margin-left: 75%;
  }
  .bootstrap-styles .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .bootstrap-styles .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .bootstrap-styles .g-md-0,
  .bootstrap-styles .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .bootstrap-styles .g-md-0,
  .bootstrap-styles .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .bootstrap-styles .g-md-1,
  .bootstrap-styles .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .bootstrap-styles .g-md-1,
  .bootstrap-styles .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .bootstrap-styles .g-md-2,
  .bootstrap-styles .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .bootstrap-styles .g-md-2,
  .bootstrap-styles .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .bootstrap-styles .g-md-3,
  .bootstrap-styles .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .bootstrap-styles .g-md-3,
  .bootstrap-styles .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .bootstrap-styles .g-md-4,
  .bootstrap-styles .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .bootstrap-styles .g-md-4,
  .bootstrap-styles .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .bootstrap-styles .g-md-5,
  .bootstrap-styles .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .bootstrap-styles .g-md-5,
  .bootstrap-styles .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .bootstrap-styles .col-lg {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 0%;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .bootstrap-styles .row-cols-lg-auto > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .bootstrap-styles .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap-styles .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap-styles .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap-styles .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap-styles .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .bootstrap-styles .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap-styles .col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .bootstrap-styles .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .bootstrap-styles .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap-styles .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap-styles .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap-styles .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .bootstrap-styles .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap-styles .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .bootstrap-styles .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .bootstrap-styles .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .bootstrap-styles .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .bootstrap-styles .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .bootstrap-styles .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap-styles .offset-lg-0 {
    margin-left: 0;
  }
  .bootstrap-styles .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .bootstrap-styles .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .bootstrap-styles .offset-lg-3 {
    margin-left: 25%;
  }
  .bootstrap-styles .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .bootstrap-styles .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .bootstrap-styles .offset-lg-6 {
    margin-left: 50%;
  }
  .bootstrap-styles .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .bootstrap-styles .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .bootstrap-styles .offset-lg-9 {
    margin-left: 75%;
  }
  .bootstrap-styles .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .bootstrap-styles .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .bootstrap-styles .g-lg-0,
  .bootstrap-styles .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .bootstrap-styles .g-lg-0,
  .bootstrap-styles .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .bootstrap-styles .g-lg-1,
  .bootstrap-styles .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .bootstrap-styles .g-lg-1,
  .bootstrap-styles .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .bootstrap-styles .g-lg-2,
  .bootstrap-styles .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .bootstrap-styles .g-lg-2,
  .bootstrap-styles .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .bootstrap-styles .g-lg-3,
  .bootstrap-styles .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .bootstrap-styles .g-lg-3,
  .bootstrap-styles .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .bootstrap-styles .g-lg-4,
  .bootstrap-styles .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .bootstrap-styles .g-lg-4,
  .bootstrap-styles .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .bootstrap-styles .g-lg-5,
  .bootstrap-styles .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .bootstrap-styles .g-lg-5,
  .bootstrap-styles .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .bootstrap-styles .col-xl {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 0%;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .bootstrap-styles .row-cols-xl-auto > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .bootstrap-styles .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap-styles .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap-styles .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap-styles .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap-styles .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .bootstrap-styles .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap-styles .col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .bootstrap-styles .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .bootstrap-styles .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap-styles .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap-styles .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap-styles .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .bootstrap-styles .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap-styles .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .bootstrap-styles .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .bootstrap-styles .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .bootstrap-styles .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .bootstrap-styles .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .bootstrap-styles .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap-styles .offset-xl-0 {
    margin-left: 0;
  }
  .bootstrap-styles .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .bootstrap-styles .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .bootstrap-styles .offset-xl-3 {
    margin-left: 25%;
  }
  .bootstrap-styles .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .bootstrap-styles .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .bootstrap-styles .offset-xl-6 {
    margin-left: 50%;
  }
  .bootstrap-styles .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .bootstrap-styles .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .bootstrap-styles .offset-xl-9 {
    margin-left: 75%;
  }
  .bootstrap-styles .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .bootstrap-styles .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .bootstrap-styles .g-xl-0,
  .bootstrap-styles .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .bootstrap-styles .g-xl-0,
  .bootstrap-styles .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .bootstrap-styles .g-xl-1,
  .bootstrap-styles .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .bootstrap-styles .g-xl-1,
  .bootstrap-styles .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .bootstrap-styles .g-xl-2,
  .bootstrap-styles .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .bootstrap-styles .g-xl-2,
  .bootstrap-styles .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .bootstrap-styles .g-xl-3,
  .bootstrap-styles .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .bootstrap-styles .g-xl-3,
  .bootstrap-styles .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .bootstrap-styles .g-xl-4,
  .bootstrap-styles .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .bootstrap-styles .g-xl-4,
  .bootstrap-styles .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .bootstrap-styles .g-xl-5,
  .bootstrap-styles .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .bootstrap-styles .g-xl-5,
  .bootstrap-styles .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .bootstrap-styles .col-xxl {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 0%;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .bootstrap-styles .row-cols-xxl-auto > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .bootstrap-styles .row-cols-xxl-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap-styles .row-cols-xxl-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap-styles .row-cols-xxl-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap-styles .row-cols-xxl-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap-styles .row-cols-xxl-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .bootstrap-styles .row-cols-xxl-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap-styles .col-xxl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .bootstrap-styles .col-xxl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .bootstrap-styles .col-xxl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap-styles .col-xxl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap-styles .col-xxl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap-styles .col-xxl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .bootstrap-styles .col-xxl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap-styles .col-xxl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .bootstrap-styles .col-xxl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .bootstrap-styles .col-xxl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .bootstrap-styles .col-xxl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .bootstrap-styles .col-xxl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .bootstrap-styles .col-xxl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap-styles .offset-xxl-0 {
    margin-left: 0;
  }
  .bootstrap-styles .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .bootstrap-styles .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .bootstrap-styles .offset-xxl-3 {
    margin-left: 25%;
  }
  .bootstrap-styles .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .bootstrap-styles .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .bootstrap-styles .offset-xxl-6 {
    margin-left: 50%;
  }
  .bootstrap-styles .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .bootstrap-styles .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .bootstrap-styles .offset-xxl-9 {
    margin-left: 75%;
  }
  .bootstrap-styles .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .bootstrap-styles .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .bootstrap-styles .g-xxl-0,
  .bootstrap-styles .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .bootstrap-styles .g-xxl-0,
  .bootstrap-styles .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .bootstrap-styles .g-xxl-1,
  .bootstrap-styles .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .bootstrap-styles .g-xxl-1,
  .bootstrap-styles .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .bootstrap-styles .g-xxl-2,
  .bootstrap-styles .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .bootstrap-styles .g-xxl-2,
  .bootstrap-styles .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .bootstrap-styles .g-xxl-3,
  .bootstrap-styles .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .bootstrap-styles .g-xxl-3,
  .bootstrap-styles .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .bootstrap-styles .g-xxl-4,
  .bootstrap-styles .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .bootstrap-styles .g-xxl-4,
  .bootstrap-styles .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .bootstrap-styles .g-xxl-5,
  .bootstrap-styles .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .bootstrap-styles .g-xxl-5,
  .bootstrap-styles .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.bootstrap-styles .btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-styles .btn {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.bootstrap-styles .btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .bootstrap-styles .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.bootstrap-styles .btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  -webkit-box-shadow: var(--bs-btn-focus-box-shadow);
          box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .bootstrap-styles .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  -webkit-box-shadow: var(--bs-btn-focus-box-shadow);
          box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .bootstrap-styles .btn, :not(.btn-check) + .bootstrap-styles .btn:active, .bootstrap-styles .btn:first-child:active, .bootstrap-styles .btn.active, .bootstrap-styles .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .bootstrap-styles .btn:focus-visible, :not(.btn-check) + .bootstrap-styles .btn:active:focus-visible, .bootstrap-styles .btn:first-child:active:focus-visible, .bootstrap-styles .btn.active:focus-visible, .bootstrap-styles .btn.show:focus-visible {
  -webkit-box-shadow: var(--bs-btn-focus-box-shadow);
          box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .bootstrap-styles .btn {
  -webkit-box-shadow: var(--bs-btn-focus-box-shadow);
          box-shadow: var(--bs-btn-focus-box-shadow);
}
.bootstrap-styles .btn:disabled, .bootstrap-styles .btn.disabled, fieldset:disabled .bootstrap-styles .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}
.bootstrap-styles .btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}
.bootstrap-styles .btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}
.bootstrap-styles .btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}
.bootstrap-styles .btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}
.bootstrap-styles .btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}
.bootstrap-styles .btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}
.bootstrap-styles .btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}
.bootstrap-styles .btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}
.bootstrap-styles .btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}
.bootstrap-styles .btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}
.bootstrap-styles .btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}
.bootstrap-styles .btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}
.bootstrap-styles .btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}
.bootstrap-styles .btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}
.bootstrap-styles .btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}
.bootstrap-styles .btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}
.bootstrap-styles .btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.bootstrap-styles .btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.bootstrap-styles .btn-link:hover {
  color: var(--bs-btn-hover-color);
}
.bootstrap-styles .btn-lg, .bootstrap-styles .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
.bootstrap-styles .btn-sm, .bootstrap-styles .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}
.bootstrap-styles .fade {
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-styles .fade {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.bootstrap-styles .fade:not(.show) {
  opacity: 0;
}
.bootstrap-styles .collapse:not(.show) {
  display: none;
}
.bootstrap-styles .collapsing {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-styles .collapsing {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.bootstrap-styles .collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  -webkit-transition: width 0.35s ease;
  -o-transition: width 0.35s ease;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-styles .collapsing.collapse-horizontal {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.bootstrap-styles .dropup,
.bootstrap-styles .dropend,
.bootstrap-styles .dropdown-mf 
.bootstrap-styles .dropstart,
.bootstrap-styles .dropup-center,
.bootstrap-styles .dropdown-center {
  position: relative;
}
.bootstrap-styles .dropdown-toggle {
  white-space: nowrap;
}
.bootstrap-styles .dropdown-toggle-arrow::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.bootstrap-styles .dropdown-toggle:empty::after {
  margin-left: 0;
}
.bootstrap-styles .dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.bootstrap-styles .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}
.bootstrap-styles .dropdown-menu-start {
  --bs-position: start;
}
.bootstrap-styles .dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}
.bootstrap-styles .dropdown-menu-end {
  --bs-position: end;
}
.bootstrap-styles .dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .bootstrap-styles .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .bootstrap-styles .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .bootstrap-styles .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .bootstrap-styles .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .bootstrap-styles .dropdown-menu-md-start {
    --bs-position: start;
  }
  .bootstrap-styles .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .bootstrap-styles .dropdown-menu-md-end {
    --bs-position: end;
  }
  .bootstrap-styles .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .bootstrap-styles .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .bootstrap-styles .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .bootstrap-styles .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .bootstrap-styles .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .bootstrap-styles .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .bootstrap-styles .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .bootstrap-styles .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .bootstrap-styles .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .bootstrap-styles .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .bootstrap-styles .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .bootstrap-styles .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .bootstrap-styles .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.bootstrap-styles .dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.bootstrap-styles .dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.bootstrap-styles .dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.bootstrap-styles .dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.bootstrap-styles .dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.bootstrap-styles .dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.bootstrap-styles .dropend .dropdown-toggle::after {
  vertical-align: 0;
}
.bootstrap-styles .dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.bootstrap-styles .dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.bootstrap-styles .dropstart .dropdown-toggle::after {
  display: none;
}
.bootstrap-styles .dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.bootstrap-styles .dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.bootstrap-styles .dropstart .dropdown-toggle::before {
  vertical-align: 0;
}
.bootstrap-styles .dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}
.bootstrap-styles .dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.bootstrap-styles .dropdown-item:hover, .bootstrap-styles .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.bootstrap-styles .dropdown-item.active, .bootstrap-styles .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.bootstrap-styles .dropdown-item.disabled, .bootstrap-styles .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}
.bootstrap-styles .dropdown-menu.show {
  display: block;
}
.bootstrap-styles .dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}
.bootstrap-styles .dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}
.bootstrap-styles .dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}
.bootstrap-styles .btn-group,
.bootstrap-styles .btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.bootstrap-styles .btn-group > .btn,
.bootstrap-styles .btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.bootstrap-styles .btn-group > .btn-check:checked + .btn,
.bootstrap-styles .btn-group > .btn-check:focus + .btn,
.bootstrap-styles .btn-group > .btn:hover,
.bootstrap-styles .btn-group > .btn:focus,
.bootstrap-styles .btn-group > .btn:active,
.bootstrap-styles .btn-group > .btn.active,
.bootstrap-styles .btn-group-vertical > .btn-check:checked + .btn,
.bootstrap-styles .btn-group-vertical > .btn-check:focus + .btn,
.bootstrap-styles .btn-group-vertical > .btn:hover,
.bootstrap-styles .btn-group-vertical > .btn:focus,
.bootstrap-styles .btn-group-vertical > .btn:active,
.bootstrap-styles .btn-group-vertical > .btn.active {
  z-index: 1;
}
.bootstrap-styles .btn-toolbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.bootstrap-styles .btn-toolbar .input-group {
  width: auto;
}
.bootstrap-styles .btn-group {
  border-radius: var(--bs-border-radius);
}
.bootstrap-styles .btn-group > :not(.btn-check:first-child) + .btn,
.bootstrap-styles .btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.bootstrap-styles .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.bootstrap-styles .btn-group > .btn.dropdown-toggle-split:first-child,
.bootstrap-styles .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.bootstrap-styles .btn-group > .btn:nth-child(n+3),
.bootstrap-styles .btn-group > :not(.btn-check) + .btn,
.bootstrap-styles .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.bootstrap-styles .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.bootstrap-styles .dropdown-toggle-split::after, .dropup .bootstrap-styles .dropdown-toggle-split::after, .dropend .bootstrap-styles .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .bootstrap-styles .dropdown-toggle-split::before {
  margin-right: 0;
}
.bootstrap-styles .btn-sm + .dropdown-toggle-split, .bootstrap-styles .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.bootstrap-styles .btn-lg + .dropdown-toggle-split, .bootstrap-styles .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.bootstrap-styles .btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.bootstrap-styles .btn-group-vertical > .btn,
.bootstrap-styles .btn-group-vertical > .btn-group {
  width: 100%;
}
.bootstrap-styles .btn-group-vertical > .btn:not(:first-child),
.bootstrap-styles .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.bootstrap-styles .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.bootstrap-styles .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.bootstrap-styles .btn-group-vertical > .btn ~ .btn,
.bootstrap-styles .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bootstrap-styles .nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  /* display: flex;
  flex-wrap: wrap; */
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.bootstrap-styles .nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-styles .nav-link {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.bootstrap-styles .nav-link:hover, .bootstrap-styles .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.bootstrap-styles .nav-link:focus-visible {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.bootstrap-styles .nav-link.disabled, .bootstrap-styles .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}
.bootstrap-styles .nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.bootstrap-styles .nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.bootstrap-styles .nav-tabs .nav-link:hover, .bootstrap-styles .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.bootstrap-styles .nav-tabs .nav-link.active,
.bootstrap-styles .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.bootstrap-styles .nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.bootstrap-styles .nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.bootstrap-styles .nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.bootstrap-styles .nav-pills .nav-link.active,
.bootstrap-styles .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}
.bootstrap-styles .nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.bootstrap-styles .nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.bootstrap-styles .nav-underline .nav-link:hover, .bootstrap-styles .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.bootstrap-styles .nav-underline .nav-link.active,
.bootstrap-styles .nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}
.bootstrap-styles .nav-fill > .nav-link,
.bootstrap-styles .nav-fill .nav-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center;
}
.bootstrap-styles .nav-justified > .nav-link,
.bootstrap-styles .nav-justified .nav-item {
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center;
}
.bootstrap-styles .nav-fill .nav-item .nav-link,
.bootstrap-styles .nav-justified .nav-item .nav-link {
  width: 100%;
}
.bootstrap-styles .tab-content > .tab-pane {
  display: none;
}
.bootstrap-styles .tab-content > .active {
  display: block;
}
.bootstrap-styles .mf-navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;

  -webkit-box-align: center;

  -webkit-align-items: center;

      -ms-flex-align: center;

          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.bootstrap-styles .navbar > .container,
.bootstrap-styles .navbar > .container-fluid,
.bootstrap-styles .navbar > .container-sm,
.bootstrap-styles .navbar > .container-md,
.bootstrap-styles .navbar > .container-lg,
.bootstrap-styles .navbar > .container-xl,
.bootstrap-styles .navbar > .container-xxl {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: inherit;
      -ms-flex-wrap: inherit;
          flex-wrap: inherit;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bootstrap-styles .navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.bootstrap-styles .navbar-brand:hover, .bootstrap-styles .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}
.bootstrap-styles .navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);

  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.bootstrap-styles .navbar-nav .nav-link.active, .bootstrap-styles .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.bootstrap-styles .navbar-nav .dropdown-menu {
  position: static;
}
.bootstrap-styles .navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.bootstrap-styles .navbar-text a,
.bootstrap-styles .navbar-text a:hover,
.bootstrap-styles .navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}
.bootstrap-styles .navbar-collapse {
  -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.bootstrap-styles .navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  -webkit-transition: var(--bs-navbar-toggler-transition);
  -o-transition: var(--bs-navbar-toggler-transition);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-styles .navbar-toggler {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.bootstrap-styles .navbar-toggler:hover {
  text-decoration: none;
}
.bootstrap-styles .navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  -webkit-box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
          box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}
.bootstrap-styles .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: 100% 100%;
          background-size: 100%;
}
.bootstrap-styles .navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .bootstrap-styles .navbar-expand-sm {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .bootstrap-styles .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .bootstrap-styles .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bootstrap-styles .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .bootstrap-styles .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .bootstrap-styles .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  .bootstrap-styles .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .bootstrap-styles .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    -webkit-transform: none !important;
        -ms-transform: none !important;
         -o-transform: none !important;
            transform: none !important;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
  .bootstrap-styles .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .bootstrap-styles .navbar-expand-sm .offcanvas .offcanvas-body {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .bootstrap-styles .navbar-expand-md {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .bootstrap-styles .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .bootstrap-styles .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bootstrap-styles .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .bootstrap-styles .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .bootstrap-styles .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  .bootstrap-styles .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .bootstrap-styles .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    -webkit-transform: none !important;
        -ms-transform: none !important;
         -o-transform: none !important;
            transform: none !important;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
  .bootstrap-styles .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .bootstrap-styles .navbar-expand-md .offcanvas .offcanvas-body {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .bootstrap-styles .navbar-expand-lg {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .bootstrap-styles .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .bootstrap-styles .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bootstrap-styles .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .bootstrap-styles .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .bootstrap-styles .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  .bootstrap-styles .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .bootstrap-styles .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    -webkit-transform: none !important;
        -ms-transform: none !important;
         -o-transform: none !important;
            transform: none !important;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
  .bootstrap-styles .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .bootstrap-styles .navbar-expand-lg .offcanvas .offcanvas-body {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .bootstrap-styles .navbar-expand-xl {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .bootstrap-styles .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .bootstrap-styles .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bootstrap-styles .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .bootstrap-styles .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .bootstrap-styles .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  .bootstrap-styles .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .bootstrap-styles .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    -webkit-transform: none !important;
        -ms-transform: none !important;
         -o-transform: none !important;
            transform: none !important;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
  .bootstrap-styles .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .bootstrap-styles .navbar-expand-xl .offcanvas .offcanvas-body {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .bootstrap-styles .navbar-expand-xxl {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .bootstrap-styles .navbar-expand-xxl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .bootstrap-styles .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bootstrap-styles .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .bootstrap-styles .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .bootstrap-styles .navbar-expand-xxl .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
  }
  .bootstrap-styles .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .bootstrap-styles .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    -webkit-transform: none !important;
        -ms-transform: none !important;
         -o-transform: none !important;
            transform: none !important;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
  .bootstrap-styles .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .bootstrap-styles .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.bootstrap-styles .navbar-expand {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.bootstrap-styles .navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}
.bootstrap-styles .navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.bootstrap-styles .navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.bootstrap-styles .navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.bootstrap-styles .navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}
.bootstrap-styles .navbar-expand .navbar-toggler {
  display: none;
}
.bootstrap-styles .navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  -webkit-transform: none !important;
      -ms-transform: none !important;
       -o-transform: none !important;
          transform: none !important;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
.bootstrap-styles .navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.bootstrap-styles .navbar-expand .offcanvas .offcanvas-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}
.bootstrap-styles .navbar-dark,
.bootstrap-styles .navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.bootstrap-styles [data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.bootstrap-styles .clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.bootstrap-styles .text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}
.bootstrap-styles .text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}
.bootstrap-styles .text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}
.bootstrap-styles .text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}
.bootstrap-styles .text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}
.bootstrap-styles .text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}
.bootstrap-styles .text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}
.bootstrap-styles .text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}
.bootstrap-styles .link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-primary:hover, .bootstrap-styles .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-secondary:hover, .bootstrap-styles .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-success:hover, .bootstrap-styles .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-info:hover, .bootstrap-styles .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-warning:hover, .bootstrap-styles .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-danger:hover, .bootstrap-styles .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-light:hover, .bootstrap-styles .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-dark:hover, .bootstrap-styles .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-body-emphasis:hover, .bootstrap-styles .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  -webkit-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
  -moz-text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
       text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}
.bootstrap-styles .focus-ring:focus {
  outline: 0;
  -webkit-box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
          box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}
.bootstrap-styles .icon-link {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 0.375rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  -moz-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
       text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.bootstrap-styles .icon-link > .bi {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  -webkit-transition: 0.2s ease-in-out transform;
  -o-transition: 0.2s ease-in-out transform;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .bootstrap-styles .icon-link > .bi {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.bootstrap-styles .icon-link-hover:hover > .bi, .bootstrap-styles .icon-link-hover:focus-visible > .bi {
  -webkit-transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
          transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}
.bootstrap-styles .ratio {
  position: relative;
  width: 100%;
}
.bootstrap-styles .ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.bootstrap-styles .ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bootstrap-styles .ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.bootstrap-styles .ratio-4x3 {
  --bs-aspect-ratio: 75%;
}
.bootstrap-styles .ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}
.bootstrap-styles .ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}
.bootstrap-styles .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.bootstrap-styles .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.bootstrap-styles .sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}
.bootstrap-styles .sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  .bootstrap-styles .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .bootstrap-styles .sticky-sm-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .bootstrap-styles .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .bootstrap-styles .sticky-md-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .bootstrap-styles .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .bootstrap-styles .sticky-lg-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .bootstrap-styles .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .bootstrap-styles .sticky-xl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .bootstrap-styles .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .bootstrap-styles .sticky-xxl-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.bootstrap-styles .hstack {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
          align-self: stretch;
}
.bootstrap-styles .vstack {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
          align-self: stretch;
}
.bootstrap-styles .visually-hidden,
.bootstrap-styles .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.bootstrap-styles .visually-hidden:not(caption),
.bootstrap-styles .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}
.bootstrap-styles .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.bootstrap-styles .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bootstrap-styles .vr {
  display: inline-block;
  -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
          align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}
.bootstrap-styles .align-baseline {
  vertical-align: baseline !important;
}
.bootstrap-styles .align-top {
  vertical-align: top !important;
}
.bootstrap-styles .align-middle {
  vertical-align: middle !important;
}
.bootstrap-styles .align-bottom {
  vertical-align: bottom !important;
}
.bootstrap-styles .align-text-bottom {
  vertical-align: text-bottom !important;
}
.bootstrap-styles .align-text-top {
  vertical-align: text-top !important;
}
.bootstrap-styles .float-start {
  float: left !important;
}
.bootstrap-styles .float-end {
  float: right !important;
}
.bootstrap-styles .float-none {
  float: none !important;
}
.bootstrap-styles .object-fit-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}
.bootstrap-styles .object-fit-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}
.bootstrap-styles .object-fit-fill {
  -o-object-fit: fill !important;
  object-fit: fill !important;
}
.bootstrap-styles .object-fit-scale {
  -o-object-fit: scale-down !important;
  object-fit: scale-down !important;
}
.bootstrap-styles .object-fit-none {
  -o-object-fit: none !important;
  object-fit: none !important;
}
.bootstrap-styles .opacity-0 {
  opacity: 0 !important;
}
.bootstrap-styles .opacity-25 {
  opacity: 0.25 !important;
}
.bootstrap-styles .opacity-50 {
  opacity: 0.5 !important;
}
.bootstrap-styles .opacity-75 {
  opacity: 0.75 !important;
}
.bootstrap-styles .opacity-100 {
  opacity: 1 !important;
}
.bootstrap-styles .overflow-auto {
  overflow: auto !important;
}
.bootstrap-styles .overflow-hidden {
  overflow: hidden !important;
}
.bootstrap-styles .overflow-visible {
  overflow: visible !important;
}
.bootstrap-styles .overflow-scroll {
  overflow: scroll !important;
}
.bootstrap-styles .overflow-x-auto {
  overflow-x: auto !important;
}
.bootstrap-styles .overflow-x-hidden {
  overflow-x: hidden !important;
}
.bootstrap-styles .overflow-x-visible {
  overflow-x: visible !important;
}
.bootstrap-styles .overflow-x-scroll {
  overflow-x: scroll !important;
}
.bootstrap-styles .overflow-y-auto {
  overflow-y: auto !important;
}
.bootstrap-styles .overflow-y-hidden {
  overflow-y: hidden !important;
}
.bootstrap-styles .overflow-y-visible {
  overflow-y: visible !important;
}
.bootstrap-styles .overflow-y-scroll {
  overflow-y: scroll !important;
}
.bootstrap-styles .d-inline {
  display: inline !important;
}
.bootstrap-styles .d-inline-block {
  display: inline-block !important;
}
.bootstrap-styles .d-block {
  display: block !important;
}
.bootstrap-styles .d-grid {
  display: grid !important;
}
.bootstrap-styles .d-inline-grid {
  display: inline-grid !important;
}
.bootstrap-styles .d-table {
  display: table !important;
}
.bootstrap-styles .d-table-row {
  display: table-row !important;
}
.bootstrap-styles .d-table-cell {
  display: table-cell !important;
}
.bootstrap-styles .d-flex {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.bootstrap-styles .d-inline-flex {
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
.bootstrap-styles .d-none {
  display: none !important;
}
.bootstrap-styles .shadow {
  -webkit-box-shadow: var(--bs-box-shadow) !important;
          box-shadow: var(--bs-box-shadow) !important;
}
.bootstrap-styles .shadow-sm {
  -webkit-box-shadow: var(--bs-box-shadow-sm) !important;
          box-shadow: var(--bs-box-shadow-sm) !important;
}
.bootstrap-styles .shadow-lg {
  -webkit-box-shadow: var(--bs-box-shadow-lg) !important;
          box-shadow: var(--bs-box-shadow-lg) !important;
}
.bootstrap-styles .shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.bootstrap-styles .focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}
.bootstrap-styles .focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}
.bootstrap-styles .focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}
.bootstrap-styles .focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}
.bootstrap-styles .focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}
.bootstrap-styles .focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}
.bootstrap-styles .focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}
.bootstrap-styles .focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}
.bootstrap-styles .position-static {
  position: static !important;
}
.bootstrap-styles .position-relative {
  position: relative !important;
}
.bootstrap-styles .position-absolute {
  position: absolute !important;
}
.bootstrap-styles .position-fixed {
  position: fixed !important;
}
.bootstrap-styles .position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.bootstrap-styles .top-0 {
  top: 0 !important;
}
.bootstrap-styles .top-50 {
  top: 50% !important;
}
.bootstrap-styles .top-100 {
  top: 100% !important;
}
.bootstrap-styles .bottom-0 {
  bottom: 0 !important;
}
.bootstrap-styles .bottom-50 {
  bottom: 50% !important;
}
.bootstrap-styles .bottom-100 {
  bottom: 100% !important;
}
.bootstrap-styles .start-0 {
  left: 0 !important;
}
.bootstrap-styles .start-50 {
  left: 50% !important;
}
.bootstrap-styles .start-100 {
  left: 100% !important;
}
.bootstrap-styles .end-0 {
  right: 0 !important;
}
.bootstrap-styles .end-50 {
  right: 50% !important;
}
.bootstrap-styles .end-100 {
  right: 100% !important;
}
.bootstrap-styles .translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
      -ms-transform: translate(-50%, -50%) !important;
       -o-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}
.bootstrap-styles .translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
      -ms-transform: translateX(-50%) !important;
       -o-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
}
.bootstrap-styles .translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
       -o-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
}
.bootstrap-styles .border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.bootstrap-styles .border-0 {
  border: 0 !important;
}
.bootstrap-styles .border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.bootstrap-styles .border-top-0 {
  border-top: 0 !important;
}
.bootstrap-styles .border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.bootstrap-styles .border-end-0 {
  border-right: 0 !important;
}
.bootstrap-styles .border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.bootstrap-styles .border-bottom-0 {
  border-bottom: 0 !important;
}
.bootstrap-styles .border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.bootstrap-styles .border-start-0 {
  border-left: 0 !important;
}
.bootstrap-styles .border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}
.bootstrap-styles .border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}
.bootstrap-styles .border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}
.bootstrap-styles .border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}
.bootstrap-styles .border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}
.bootstrap-styles .border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}
.bootstrap-styles .border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}
.bootstrap-styles .border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}
.bootstrap-styles .border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}
.bootstrap-styles .border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}
.bootstrap-styles .border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}
.bootstrap-styles .border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}
.bootstrap-styles .border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}
.bootstrap-styles .border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}
.bootstrap-styles .border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}
.bootstrap-styles .border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}
.bootstrap-styles .border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}
.bootstrap-styles .border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}
.bootstrap-styles .border-1 {
  border-width: 1px !important;
}
.bootstrap-styles .border-2 {
  border-width: 2px !important;
}
.bootstrap-styles .border-3 {
  border-width: 3px !important;
}
.bootstrap-styles .border-4 {
  border-width: 4px !important;
}
.bootstrap-styles .border-5 {
  border-width: 5px !important;
}
.bootstrap-styles .border-opacity-10 {
  --bs-border-opacity: 0.1;
}
.bootstrap-styles .border-opacity-25 {
  --bs-border-opacity: 0.25;
}
.bootstrap-styles .border-opacity-50 {
  --bs-border-opacity: 0.5;
}
.bootstrap-styles .border-opacity-75 {
  --bs-border-opacity: 0.75;
}
.bootstrap-styles .border-opacity-100 {
  --bs-border-opacity: 1;
}
.bootstrap-styles .w-25 {
  width: 25% !important;
}
.bootstrap-styles .w-50 {
  width: 50% !important;
}
.bootstrap-styles .w-75 {
  width: 75% !important;
}
.bootstrap-styles .w-100 {
  width: 100% !important;
}
.bootstrap-styles .w-auto {
  width: auto !important;
}
.bootstrap-styles .mw-100 {
  max-width: 100% !important;
}
.bootstrap-styles .vw-100 {
  width: 100vw !important;
}
.bootstrap-styles .min-vw-100 {
  min-width: 100vw !important;
}
.bootstrap-styles .h-25 {
  height: 25% !important;
}
.bootstrap-styles .h-50 {
  height: 50% !important;
}
.bootstrap-styles .h-75 {
  height: 75% !important;
}
.bootstrap-styles .h-100 {
  height: 100% !important;
}
.bootstrap-styles .h-auto {
  height: auto !important;
}
.bootstrap-styles .mh-100 {
  max-height: 100% !important;
}
.bootstrap-styles .vh-100 {
  height: 100vh !important;
}
.bootstrap-styles .min-vh-100 {
  min-height: 100vh !important;
}
.bootstrap-styles .flex-fill {
  -webkit-box-flex: 1 !important;
  -webkit-flex: 1 1 auto !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
}
.bootstrap-styles .flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
}
.bootstrap-styles .flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: column !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}
.bootstrap-styles .flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -webkit-flex-direction: row-reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}
.bootstrap-styles .flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -webkit-flex-direction: column-reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}
.bootstrap-styles .flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -webkit-flex-grow: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
}
.bootstrap-styles .flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -webkit-flex-grow: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
}
.bootstrap-styles .flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
      -ms-flex-negative: 0 !important;
          flex-shrink: 0 !important;
}
.bootstrap-styles .flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
      -ms-flex-negative: 1 !important;
          flex-shrink: 1 !important;
}
.bootstrap-styles .flex-wrap {
  -webkit-flex-wrap: wrap !important;
      -ms-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
}
.bootstrap-styles .flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
      -ms-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
}
.bootstrap-styles .flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
      -ms-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important;
}
.bootstrap-styles .justify-content-start {
  -webkit-box-pack: start !important;
  -webkit-justify-content: flex-start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
}
.bootstrap-styles .justify-content-end {
  -webkit-box-pack: end !important;
  -webkit-justify-content: flex-end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}
.bootstrap-styles .justify-content-center {
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}
.bootstrap-styles .justify-content-between {
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}
.bootstrap-styles .justify-content-around {
  -webkit-justify-content: space-around !important;
      -ms-flex-pack: distribute !important;
          justify-content: space-around !important;
}
.bootstrap-styles .justify-content-evenly {
  -webkit-box-pack: space-evenly !important;
  -webkit-justify-content: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
          justify-content: space-evenly !important;
}
.bootstrap-styles .align-items-start {
  -webkit-box-align: start !important;
  -webkit-align-items: flex-start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important;
}
.bootstrap-styles .align-items-end {
  -webkit-box-align: end !important;
  -webkit-align-items: flex-end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important;
}
.bootstrap-styles .align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}
.bootstrap-styles .align-items-baseline {
  -webkit-box-align: baseline !important;
  -webkit-align-items: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important;
}
.bootstrap-styles .align-items-stretch {
  -webkit-box-align: stretch !important;
  -webkit-align-items: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important;
}
.bootstrap-styles .align-content-start {
  -webkit-align-content: flex-start !important;
      -ms-flex-line-pack: start !important;
          align-content: flex-start !important;
}
.bootstrap-styles .align-content-end {
  -webkit-align-content: flex-end !important;
      -ms-flex-line-pack: end !important;
          align-content: flex-end !important;
}
.bootstrap-styles .align-content-center {
  -webkit-align-content: center !important;
      -ms-flex-line-pack: center !important;
          align-content: center !important;
}
.bootstrap-styles .align-content-between {
  -webkit-align-content: space-between !important;
      -ms-flex-line-pack: justify !important;
          align-content: space-between !important;
}
.bootstrap-styles .align-content-around {
  -webkit-align-content: space-around !important;
      -ms-flex-line-pack: distribute !important;
          align-content: space-around !important;
}
.bootstrap-styles .align-content-stretch {
  -webkit-align-content: stretch !important;
      -ms-flex-line-pack: stretch !important;
          align-content: stretch !important;
}
.bootstrap-styles .align-self-auto {
  -webkit-align-self: auto !important;
      -ms-flex-item-align: auto !important;
          align-self: auto !important;
}
.bootstrap-styles .align-self-start {
  -webkit-align-self: flex-start !important;
      -ms-flex-item-align: start !important;
          align-self: flex-start !important;
}
.bootstrap-styles .align-self-end {
  -webkit-align-self: flex-end !important;
      -ms-flex-item-align: end !important;
          align-self: flex-end !important;
}
.bootstrap-styles .align-self-center {
  -webkit-align-self: center !important;
      -ms-flex-item-align: center !important;
          align-self: center !important;
}
.bootstrap-styles .align-self-baseline {
  -webkit-align-self: baseline !important;
      -ms-flex-item-align: baseline !important;
          align-self: baseline !important;
}
.bootstrap-styles .align-self-stretch {
  -webkit-align-self: stretch !important;
      -ms-flex-item-align: stretch !important;
          align-self: stretch !important;
}
.bootstrap-styles .order-first {
  -webkit-box-ordinal-group: 0 !important;
  -webkit-order: -1 !important;
      -ms-flex-order: -1 !important;
          order: -1 !important;
}
.bootstrap-styles .order-0 {
  -webkit-box-ordinal-group: 1 !important;
  -webkit-order: 0 !important;
      -ms-flex-order: 0 !important;
          order: 0 !important;
}
.bootstrap-styles .order-1 {
  -webkit-box-ordinal-group: 2 !important;
  -webkit-order: 1 !important;
      -ms-flex-order: 1 !important;
          order: 1 !important;
}
.bootstrap-styles .order-2 {
  -webkit-box-ordinal-group: 3 !important;
  -webkit-order: 2 !important;
      -ms-flex-order: 2 !important;
          order: 2 !important;
}
.bootstrap-styles .order-3 {
  -webkit-box-ordinal-group: 4 !important;
  -webkit-order: 3 !important;
      -ms-flex-order: 3 !important;
          order: 3 !important;
}
.bootstrap-styles .order-4 {
  -webkit-box-ordinal-group: 5 !important;
  -webkit-order: 4 !important;
      -ms-flex-order: 4 !important;
          order: 4 !important;
}
.bootstrap-styles .order-5 {
  -webkit-box-ordinal-group: 6 !important;
  -webkit-order: 5 !important;
      -ms-flex-order: 5 !important;
          order: 5 !important;
}
.bootstrap-styles .order-last {
  -webkit-box-ordinal-group: 7 !important;
  -webkit-order: 6 !important;
      -ms-flex-order: 6 !important;
          order: 6 !important;
}
.bootstrap-styles .m-0 {
  margin: 0 !important;
}
.bootstrap-styles .m-1 {
  margin: 0.25rem !important;
}
.bootstrap-styles .m-2 {
  margin: 0.5rem !important;
}
.bootstrap-styles .m-3 {
  margin: 1rem !important;
}
.bootstrap-styles .m-4 {
  margin: 1.5rem !important;
}
.bootstrap-styles .m-5 {
  margin: 3rem !important;
}
.bootstrap-styles .m-auto {
  margin: auto !important;
}
.bootstrap-styles .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.bootstrap-styles .mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.bootstrap-styles .mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.bootstrap-styles .mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.bootstrap-styles .mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.bootstrap-styles .mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.bootstrap-styles .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.bootstrap-styles .my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.bootstrap-styles .my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.bootstrap-styles .my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.bootstrap-styles .my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.bootstrap-styles .my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.bootstrap-styles .my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.bootstrap-styles .my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.bootstrap-styles .mt-0 {
  margin-top: 0 !important;
}
.bootstrap-styles .mt-1 {
  margin-top: 0.25rem !important;
}
.bootstrap-styles .mt-2 {
  margin-top: 0.5rem !important;
}
.bootstrap-styles .mt-3 {
  margin-top: 1rem !important;
}
.bootstrap-styles .mt-4 {
  margin-top: 1.5rem !important;
}
.bootstrap-styles .mt-5 {
  margin-top: 3rem !important;
}
.bootstrap-styles .mt-auto {
  margin-top: auto !important;
}
.bootstrap-styles .me-0 {
  margin-right: 0 !important;
}
.bootstrap-styles .me-1 {
  margin-right: 0.25rem !important;
}
.bootstrap-styles .me-2 {
  margin-right: 0.5rem !important;
}
.bootstrap-styles .me-3 {
  margin-right: 1rem !important;
}
.bootstrap-styles .me-4 {
  margin-right: 1.5rem !important;
}
.bootstrap-styles .me-5 {
  margin-right: 3rem !important;
}
.bootstrap-styles .me-auto {
  margin-right: auto !important;
}
.bootstrap-styles .mb-0 {
  margin-bottom: 0 !important;
}
.bootstrap-styles .mb-1 {
  margin-bottom: 0.25rem !important;
}
.bootstrap-styles .mb-2 {
  margin-bottom: 0.5rem !important;
}
.bootstrap-styles .mb-3 {
  margin-bottom: 1rem !important;
}
.bootstrap-styles .mb-4 {
  margin-bottom: 1.5rem !important;
}
.bootstrap-styles .mb-5 {
  margin-bottom: 3rem !important;
}
.bootstrap-styles .mb-auto {
  margin-bottom: auto !important;
}
.bootstrap-styles .ms-0 {
  margin-left: 0 !important;
}
.bootstrap-styles .ms-1 {
  margin-left: 0.25rem !important;
}
.bootstrap-styles .ms-2 {
  margin-left: 0.5rem !important;
}
.bootstrap-styles .ms-3 {
  margin-left: 1rem !important;
}
.bootstrap-styles .ms-4 {
  margin-left: 1.5rem !important;
}
.bootstrap-styles .ms-5 {
  margin-left: 3rem !important;
}
.bootstrap-styles .ms-auto {
  margin-left: auto !important;
}
.bootstrap-styles .p-0 {
  padding: 0 !important;
}
.bootstrap-styles .p-1 {
  padding: 0.25rem !important;
}
.bootstrap-styles .p-2 {
  padding: 0.5rem !important;
}
.bootstrap-styles .p-3 {
  padding: 1rem !important;
}
.bootstrap-styles .p-4 {
  padding: 1.5rem !important;
}
.bootstrap-styles .p-5 {
  padding: 3rem !important;
}
.bootstrap-styles .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.bootstrap-styles .px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.bootstrap-styles .px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.bootstrap-styles .px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.bootstrap-styles .px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.bootstrap-styles .px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.bootstrap-styles .py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.bootstrap-styles .py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.bootstrap-styles .py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.bootstrap-styles .py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.bootstrap-styles .py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.bootstrap-styles .py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.bootstrap-styles .pt-0 {
  padding-top: 0 !important;
}
.bootstrap-styles .pt-1 {
  padding-top: 0.25rem !important;
}
.bootstrap-styles .pt-2 {
  padding-top: 0.5rem !important;
}
.bootstrap-styles .pt-3 {
  padding-top: 1rem !important;
}
.bootstrap-styles .pt-4 {
  padding-top: 1.5rem !important;
}
.bootstrap-styles .pt-5 {
  padding-top: 3rem !important;
}
.bootstrap-styles .pe-0 {
  padding-right: 0 !important;
}
.bootstrap-styles .pe-1 {
  padding-right: 0.25rem !important;
}
.bootstrap-styles .pe-2 {
  padding-right: 0.5rem !important;
}
.bootstrap-styles .pe-3 {
  padding-right: 1rem !important;
}
.bootstrap-styles .pe-4 {
  padding-right: 1.5rem !important;
}
.bootstrap-styles .pe-5 {
  padding-right: 3rem !important;
}
.bootstrap-styles .pb-0 {
  padding-bottom: 0 !important;
}
.bootstrap-styles .pb-1 {
  padding-bottom: 0.25rem !important;
}
.bootstrap-styles .pb-2 {
  padding-bottom: 0.5rem !important;
}
.bootstrap-styles .pb-3 {
  padding-bottom: 1rem !important;
}
.bootstrap-styles .pb-4 {
  padding-bottom: 1.5rem !important;
}
.bootstrap-styles .pb-5 {
  padding-bottom: 3rem !important;
}
.bootstrap-styles .ps-0 {
  padding-left: 0 !important;
}
.bootstrap-styles .ps-1 {
  padding-left: 0.25rem !important;
}
.bootstrap-styles .ps-2 {
  padding-left: 0.5rem !important;
}
.bootstrap-styles .ps-3 {
  padding-left: 1rem !important;
}
.bootstrap-styles .ps-4 {
  padding-left: 1.5rem !important;
}
.bootstrap-styles .ps-5 {
  padding-left: 3rem !important;
}
.bootstrap-styles .gap-0 {
  gap: 0 !important;
}
.bootstrap-styles .gap-1 {
  gap: 0.25rem !important;
}
.bootstrap-styles .gap-2 {
  gap: 0.5rem !important;
}
.bootstrap-styles .gap-3 {
  gap: 1rem !important;
}
.bootstrap-styles .gap-4 {
  gap: 1.5rem !important;
}
.bootstrap-styles .gap-5 {
  gap: 3rem !important;
}
.bootstrap-styles .row-gap-0 {
  row-gap: 0 !important;
}
.bootstrap-styles .row-gap-1 {
  row-gap: 0.25rem !important;
}
.bootstrap-styles .row-gap-2 {
  row-gap: 0.5rem !important;
}
.bootstrap-styles .row-gap-3 {
  row-gap: 1rem !important;
}
.bootstrap-styles .row-gap-4 {
  row-gap: 1.5rem !important;
}
.bootstrap-styles .row-gap-5 {
  row-gap: 3rem !important;
}
.bootstrap-styles .column-gap-0 {
  -moz-column-gap: 0 !important;
  -webkit-column-gap: 0 !important;
          column-gap: 0 !important;
}
.bootstrap-styles .column-gap-1 {
  -moz-column-gap: 0.25rem !important;
  -webkit-column-gap: 0.25rem !important;
          column-gap: 0.25rem !important;
}
.bootstrap-styles .column-gap-2 {
  -moz-column-gap: 0.5rem !important;
  -webkit-column-gap: 0.5rem !important;
          column-gap: 0.5rem !important;
}
.bootstrap-styles .column-gap-3 {
  -moz-column-gap: 1rem !important;
  -webkit-column-gap: 1rem !important;
          column-gap: 1rem !important;
}
.bootstrap-styles .column-gap-4 {
  -moz-column-gap: 1.5rem !important;
  -webkit-column-gap: 1.5rem !important;
          column-gap: 1.5rem !important;
}
.bootstrap-styles .column-gap-5 {
  -moz-column-gap: 3rem !important;
  -webkit-column-gap: 3rem !important;
          column-gap: 3rem !important;
}
.bootstrap-styles .font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
.bootstrap-styles .fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}
.bootstrap-styles .fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}
.bootstrap-styles .fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.bootstrap-styles .fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
.bootstrap-styles .fs-5 {
  font-size: 1.25rem !important;
}
.bootstrap-styles .fs-6 {
  font-size: 1rem !important;
}
.bootstrap-styles .fst-italic {
  font-style: italic !important;
}
.bootstrap-styles .fst-normal {
  font-style: normal !important;
}
.bootstrap-styles .fw-lighter {
  font-weight: lighter !important;
}
.bootstrap-styles .fw-light {
  font-weight: 300 !important;
}
.bootstrap-styles .fw-normal {
  font-weight: 400 !important;
}
.bootstrap-styles .fw-medium {
  font-weight: 500 !important;
}
.bootstrap-styles .fw-semibold {
  font-weight: 600 !important;
}
.bootstrap-styles .fw-bold {
  font-weight: 700 !important;
}
.bootstrap-styles .fw-bolder {
  font-weight: bolder !important;
}
.bootstrap-styles .lh-1 {
  line-height: 1 !important;
}
.bootstrap-styles .lh-sm {
  line-height: 1.25 !important;
}
.bootstrap-styles .lh-base {
  line-height: 1.5 !important;
}
.bootstrap-styles .lh-lg {
  line-height: 2 !important;
}
.bootstrap-styles .text-start {
  text-align: left !important;
}
.bootstrap-styles .text-end {
  text-align: right !important;
}
.bootstrap-styles .text-center {
  text-align: center !important;
}
.bootstrap-styles .text-decoration-none {
  text-decoration: none !important;
}
.bootstrap-styles .text-decoration-underline {
  text-decoration: underline !important;
}
.bootstrap-styles .text-decoration-line-through {
  text-decoration: line-through !important;
}
.bootstrap-styles .text-lowercase {
  text-transform: lowercase !important;
}
.bootstrap-styles .text-uppercase {
  text-transform: uppercase !important;
}
.bootstrap-styles .text-capitalize {
  text-transform: capitalize !important;
}
.bootstrap-styles .text-wrap {
  white-space: normal !important;
}
.bootstrap-styles .text-nowrap {
  white-space: nowrap !important;
}
.bootstrap-styles .text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.bootstrap-styles .text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.bootstrap-styles .text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.bootstrap-styles .text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
.bootstrap-styles .text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
.bootstrap-styles .text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
.bootstrap-styles .text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
.bootstrap-styles .text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
.bootstrap-styles .text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
.bootstrap-styles .text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.bootstrap-styles .text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.bootstrap-styles .text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
.bootstrap-styles .text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}
.bootstrap-styles .text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.bootstrap-styles .text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
.bootstrap-styles .text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}
.bootstrap-styles .text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}
.bootstrap-styles .text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}
.bootstrap-styles .text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.bootstrap-styles .text-opacity-25 {
  --bs-text-opacity: 0.25;
}
.bootstrap-styles .text-opacity-50 {
  --bs-text-opacity: 0.5;
}
.bootstrap-styles .text-opacity-75 {
  --bs-text-opacity: 0.75;
}
.bootstrap-styles .text-opacity-100 {
  --bs-text-opacity: 1;
}
.bootstrap-styles .text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}
.bootstrap-styles .text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}
.bootstrap-styles .text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}
.bootstrap-styles .text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}
.bootstrap-styles .text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}
.bootstrap-styles .text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}
.bootstrap-styles .text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}
.bootstrap-styles .text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}
.bootstrap-styles .link-opacity-10 {
  --bs-link-opacity: 0.1;
}
.bootstrap-styles .link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}
.bootstrap-styles .link-opacity-25 {
  --bs-link-opacity: 0.25;
}
.bootstrap-styles .link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}
.bootstrap-styles .link-opacity-50 {
  --bs-link-opacity: 0.5;
}
.bootstrap-styles .link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}
.bootstrap-styles .link-opacity-75 {
  --bs-link-opacity: 0.75;
}
.bootstrap-styles .link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}
.bootstrap-styles .link-opacity-100 {
  --bs-link-opacity: 1;
}
.bootstrap-styles .link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}
.bootstrap-styles .link-offset-1 {
  text-underline-offset: 0.125em !important;
}
.bootstrap-styles .link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}
.bootstrap-styles .link-offset-2 {
  text-underline-offset: 0.25em !important;
}
.bootstrap-styles .link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}
.bootstrap-styles .link-offset-3 {
  text-underline-offset: 0.375em !important;
}
.bootstrap-styles .link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}
.bootstrap-styles .link-underline-primary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
  -moz-text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
       text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}
.bootstrap-styles .link-underline-secondary {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
  -moz-text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
       text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}
.bootstrap-styles .link-underline-success {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
  -moz-text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
       text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}
.bootstrap-styles .link-underline-info {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
  -moz-text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
       text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}
.bootstrap-styles .link-underline-warning {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
  -moz-text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
       text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}
.bootstrap-styles .link-underline-danger {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
  -moz-text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
       text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}
.bootstrap-styles .link-underline-light {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
  -moz-text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
       text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}
.bootstrap-styles .link-underline-dark {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
  -moz-text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
       text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}
.bootstrap-styles .link-underline {
  --bs-link-underline-opacity: 1;
  -webkit-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
  -moz-text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
       text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.bootstrap-styles .link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}
.bootstrap-styles .link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}
.bootstrap-styles .link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}
.bootstrap-styles .link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}
.bootstrap-styles .link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}
.bootstrap-styles .link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}
.bootstrap-styles .link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}
.bootstrap-styles .link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}
.bootstrap-styles .link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}
.bootstrap-styles .link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}
.bootstrap-styles .link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}
.bootstrap-styles .link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}
.bootstrap-styles .bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}
.bootstrap-styles .bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}
.bootstrap-styles .bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}
.bootstrap-styles .bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
.bootstrap-styles .bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}
.bootstrap-styles .bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
.bootstrap-styles .bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.bootstrap-styles .bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
.bootstrap-styles .bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.bootstrap-styles .bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.bootstrap-styles .bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}
.bootstrap-styles .bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
.bootstrap-styles .bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}
.bootstrap-styles .bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}
.bootstrap-styles .bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.bootstrap-styles .bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
.bootstrap-styles .bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
.bootstrap-styles .bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
.bootstrap-styles .bg-opacity-100 {
  --bs-bg-opacity: 1;
}
.bootstrap-styles .bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}
.bootstrap-styles .bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}
.bootstrap-styles .bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}
.bootstrap-styles .bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}
.bootstrap-styles .bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}
.bootstrap-styles .bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}
.bootstrap-styles .bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}
.bootstrap-styles .bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}
.bootstrap-styles .bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.bootstrap-styles .user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
      user-select: all !important;
}
.bootstrap-styles .user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
      user-select: auto !important;
}
.bootstrap-styles .user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
      user-select: none !important;
}
.bootstrap-styles .pe-none {
  pointer-events: none !important;
}
.bootstrap-styles .pe-auto {
  pointer-events: auto !important;
}
.bootstrap-styles .rounded {
  border-radius: var(--bs-border-radius) !important;
}
.bootstrap-styles .rounded-0 {
  border-radius: 0 !important;
}
.bootstrap-styles .rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}
.bootstrap-styles .rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}
.bootstrap-styles .rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}
.bootstrap-styles .rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}
.bootstrap-styles .rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}
.bootstrap-styles .rounded-circle {
  border-radius: 50% !important;
}
.bootstrap-styles .rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}
.bootstrap-styles .rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
.bootstrap-styles .rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.bootstrap-styles .rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}
.bootstrap-styles .rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}
.bootstrap-styles .rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}
.bootstrap-styles .rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}
.bootstrap-styles .rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}
.bootstrap-styles .rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}
.bootstrap-styles .rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}
.bootstrap-styles .rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.bootstrap-styles .rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.bootstrap-styles .rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}
.bootstrap-styles .rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.bootstrap-styles .rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}
.bootstrap-styles .rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}
.bootstrap-styles .rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}
.bootstrap-styles .rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.bootstrap-styles .rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}
.bootstrap-styles .rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.bootstrap-styles .rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.bootstrap-styles .rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}
.bootstrap-styles .rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.bootstrap-styles .rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}
.bootstrap-styles .rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}
.bootstrap-styles .rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}
.bootstrap-styles .rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.bootstrap-styles .rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}
.bootstrap-styles .rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
.bootstrap-styles .rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.bootstrap-styles .rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}
.bootstrap-styles .rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}
.bootstrap-styles .rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}
.bootstrap-styles .rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}
.bootstrap-styles .rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}
.bootstrap-styles .rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}
.bootstrap-styles .rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}
.bootstrap-styles .visible {
  visibility: visible !important;
}
.bootstrap-styles .invisible {
  visibility: hidden !important;
}
.bootstrap-styles .z-n1 {
  z-index: -1 !important;
}
.bootstrap-styles .z-0 {
  z-index: 0 !important;
}
.bootstrap-styles .z-1 {
  z-index: 1 !important;
}
.bootstrap-styles .z-2 {
  z-index: 2 !important;
}
.bootstrap-styles .z-3 {
  z-index: 3 !important;
}
@media (min-width: 576px) {
  .bootstrap-styles .float-sm-start {
    float: left !important;
  }
  .bootstrap-styles .float-sm-end {
    float: right !important;
  }
  .bootstrap-styles .float-sm-none {
    float: none !important;
  }
  .bootstrap-styles .object-fit-sm-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .bootstrap-styles .object-fit-sm-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .bootstrap-styles .object-fit-sm-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .bootstrap-styles .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .bootstrap-styles .object-fit-sm-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .bootstrap-styles .d-sm-inline {
    display: inline !important;
  }
  .bootstrap-styles .d-sm-inline-block {
    display: inline-block !important;
  }
  .bootstrap-styles .d-sm-block {
    display: block !important;
  }
  .bootstrap-styles .d-sm-grid {
    display: grid !important;
  }
  .bootstrap-styles .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .bootstrap-styles .d-sm-table {
    display: table !important;
  }
  .bootstrap-styles .d-sm-table-row {
    display: table-row !important;
  }
  .bootstrap-styles .d-sm-table-cell {
    display: table-cell !important;
  }
  .bootstrap-styles .d-sm-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .bootstrap-styles .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .bootstrap-styles .d-sm-none {
    display: none !important;
  }
  .bootstrap-styles .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .bootstrap-styles .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .bootstrap-styles .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .bootstrap-styles .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .bootstrap-styles .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .bootstrap-styles .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .bootstrap-styles .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .bootstrap-styles .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important;
  }
  .bootstrap-styles .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important;
  }
  .bootstrap-styles .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .bootstrap-styles .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .bootstrap-styles .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .bootstrap-styles .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .bootstrap-styles .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .bootstrap-styles .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .bootstrap-styles .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .bootstrap-styles .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important;
  }
  .bootstrap-styles .justify-content-sm-evenly {
    -webkit-box-pack: space-evenly !important;
    -webkit-justify-content: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .bootstrap-styles .align-items-sm-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .bootstrap-styles .align-items-sm-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .bootstrap-styles .align-items-sm-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .bootstrap-styles .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .bootstrap-styles .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .bootstrap-styles .align-content-sm-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important;
  }
  .bootstrap-styles .align-content-sm-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important;
  }
  .bootstrap-styles .align-content-sm-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important;
  }
  .bootstrap-styles .align-content-sm-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important;
  }
  .bootstrap-styles .align-content-sm-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important;
  }
  .bootstrap-styles .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important;
  }
  .bootstrap-styles .align-self-sm-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important;
  }
  .bootstrap-styles .align-self-sm-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important;
  }
  .bootstrap-styles .align-self-sm-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important;
  }
  .bootstrap-styles .align-self-sm-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important;
  }
  .bootstrap-styles .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important;
  }
  .bootstrap-styles .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important;
  }
  .bootstrap-styles .order-sm-first {
    -webkit-box-ordinal-group: 0 !important;
    -webkit-order: -1 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .bootstrap-styles .order-sm-0 {
    -webkit-box-ordinal-group: 1 !important;
    -webkit-order: 0 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .bootstrap-styles .order-sm-1 {
    -webkit-box-ordinal-group: 2 !important;
    -webkit-order: 1 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .bootstrap-styles .order-sm-2 {
    -webkit-box-ordinal-group: 3 !important;
    -webkit-order: 2 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .bootstrap-styles .order-sm-3 {
    -webkit-box-ordinal-group: 4 !important;
    -webkit-order: 3 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .bootstrap-styles .order-sm-4 {
    -webkit-box-ordinal-group: 5 !important;
    -webkit-order: 4 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .bootstrap-styles .order-sm-5 {
    -webkit-box-ordinal-group: 6 !important;
    -webkit-order: 5 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .bootstrap-styles .order-sm-last {
    -webkit-box-ordinal-group: 7 !important;
    -webkit-order: 6 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .bootstrap-styles .m-sm-0 {
    margin: 0 !important;
  }
  .bootstrap-styles .m-sm-1 {
    margin: 0.25rem !important;
  }
  .bootstrap-styles .m-sm-2 {
    margin: 0.5rem !important;
  }
  .bootstrap-styles .m-sm-3 {
    margin: 1rem !important;
  }
  .bootstrap-styles .m-sm-4 {
    margin: 1.5rem !important;
  }
  .bootstrap-styles .m-sm-5 {
    margin: 3rem !important;
  }
  .bootstrap-styles .m-sm-auto {
    margin: auto !important;
  }
  .bootstrap-styles .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .bootstrap-styles .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .bootstrap-styles .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .bootstrap-styles .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .bootstrap-styles .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .bootstrap-styles .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .bootstrap-styles .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .bootstrap-styles .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .bootstrap-styles .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-styles .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-styles .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .bootstrap-styles .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-styles .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .bootstrap-styles .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .bootstrap-styles .mt-sm-0 {
    margin-top: 0 !important;
  }
  .bootstrap-styles .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap-styles .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap-styles .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .bootstrap-styles .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap-styles .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .bootstrap-styles .mt-sm-auto {
    margin-top: auto !important;
  }
  .bootstrap-styles .me-sm-0 {
    margin-right: 0 !important;
  }
  .bootstrap-styles .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap-styles .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap-styles .me-sm-3 {
    margin-right: 1rem !important;
  }
  .bootstrap-styles .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap-styles .me-sm-5 {
    margin-right: 3rem !important;
  }
  .bootstrap-styles .me-sm-auto {
    margin-right: auto !important;
  }
  .bootstrap-styles .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap-styles .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-styles .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-styles .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap-styles .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-styles .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap-styles .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .bootstrap-styles .ms-sm-0 {
    margin-left: 0 !important;
  }
  .bootstrap-styles .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap-styles .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap-styles .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .bootstrap-styles .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap-styles .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .bootstrap-styles .ms-sm-auto {
    margin-left: auto !important;
  }
  .bootstrap-styles .p-sm-0 {
    padding: 0 !important;
  }
  .bootstrap-styles .p-sm-1 {
    padding: 0.25rem !important;
  }
  .bootstrap-styles .p-sm-2 {
    padding: 0.5rem !important;
  }
  .bootstrap-styles .p-sm-3 {
    padding: 1rem !important;
  }
  .bootstrap-styles .p-sm-4 {
    padding: 1.5rem !important;
  }
  .bootstrap-styles .p-sm-5 {
    padding: 3rem !important;
  }
  .bootstrap-styles .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .bootstrap-styles .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .bootstrap-styles .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .bootstrap-styles .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .bootstrap-styles .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .bootstrap-styles .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .bootstrap-styles .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .bootstrap-styles .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-styles .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-styles .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .bootstrap-styles .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-styles .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .bootstrap-styles .pt-sm-0 {
    padding-top: 0 !important;
  }
  .bootstrap-styles .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap-styles .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap-styles .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .bootstrap-styles .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap-styles .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .bootstrap-styles .pe-sm-0 {
    padding-right: 0 !important;
  }
  .bootstrap-styles .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap-styles .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap-styles .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .bootstrap-styles .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap-styles .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .bootstrap-styles .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap-styles .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-styles .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-styles .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap-styles .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-styles .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap-styles .ps-sm-0 {
    padding-left: 0 !important;
  }
  .bootstrap-styles .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap-styles .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap-styles .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .bootstrap-styles .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap-styles .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .bootstrap-styles .gap-sm-0 {
    gap: 0 !important;
  }
  .bootstrap-styles .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .bootstrap-styles .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .bootstrap-styles .gap-sm-3 {
    gap: 1rem !important;
  }
  .bootstrap-styles .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .bootstrap-styles .gap-sm-5 {
    gap: 3rem !important;
  }
  .bootstrap-styles .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .bootstrap-styles .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .bootstrap-styles .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .bootstrap-styles .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .bootstrap-styles .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .bootstrap-styles .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .bootstrap-styles .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
    -webkit-column-gap: 0 !important;
            column-gap: 0 !important;
  }
  .bootstrap-styles .column-gap-sm-1 {
    -moz-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }
  .bootstrap-styles .column-gap-sm-2 {
    -moz-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }
  .bootstrap-styles .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }
  .bootstrap-styles .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }
  .bootstrap-styles .column-gap-sm-5 {
    -moz-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }
  .bootstrap-styles .text-sm-start {
    text-align: left !important;
  }
  .bootstrap-styles .text-sm-end {
    text-align: right !important;
  }
  .bootstrap-styles .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .bootstrap-styles .float-md-start {
    float: left !important;
  }
  .bootstrap-styles .float-md-end {
    float: right !important;
  }
  .bootstrap-styles .float-md-none {
    float: none !important;
  }
  .bootstrap-styles .object-fit-md-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .bootstrap-styles .object-fit-md-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .bootstrap-styles .object-fit-md-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .bootstrap-styles .object-fit-md-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .bootstrap-styles .object-fit-md-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .bootstrap-styles .d-md-inline {
    display: inline !important;
  }
  .bootstrap-styles .d-md-inline-block {
    display: inline-block !important;
  }
  .bootstrap-styles .d-md-block {
    display: block !important;
  }
  .bootstrap-styles .d-md-grid {
    display: grid !important;
  }
  .bootstrap-styles .d-md-inline-grid {
    display: inline-grid !important;
  }
  .bootstrap-styles .d-md-table {
    display: table !important;
  }
  .bootstrap-styles .d-md-table-row {
    display: table-row !important;
  }
  .bootstrap-styles .d-md-table-cell {
    display: table-cell !important;
  }
  .bootstrap-styles .d-md-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .bootstrap-styles .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .bootstrap-styles .d-md-none {
    display: none !important;
  }
  .bootstrap-styles .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .bootstrap-styles .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .bootstrap-styles .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .bootstrap-styles .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .bootstrap-styles .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .bootstrap-styles .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .bootstrap-styles .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .bootstrap-styles .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important;
  }
  .bootstrap-styles .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important;
  }
  .bootstrap-styles .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .bootstrap-styles .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .bootstrap-styles .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .bootstrap-styles .justify-content-md-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .bootstrap-styles .justify-content-md-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .bootstrap-styles .justify-content-md-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .bootstrap-styles .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .bootstrap-styles .justify-content-md-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important;
  }
  .bootstrap-styles .justify-content-md-evenly {
    -webkit-box-pack: space-evenly !important;
    -webkit-justify-content: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .bootstrap-styles .align-items-md-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .bootstrap-styles .align-items-md-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .bootstrap-styles .align-items-md-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .bootstrap-styles .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .bootstrap-styles .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .bootstrap-styles .align-content-md-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important;
  }
  .bootstrap-styles .align-content-md-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important;
  }
  .bootstrap-styles .align-content-md-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important;
  }
  .bootstrap-styles .align-content-md-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important;
  }
  .bootstrap-styles .align-content-md-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important;
  }
  .bootstrap-styles .align-content-md-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important;
  }
  .bootstrap-styles .align-self-md-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important;
  }
  .bootstrap-styles .align-self-md-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important;
  }
  .bootstrap-styles .align-self-md-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important;
  }
  .bootstrap-styles .align-self-md-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important;
  }
  .bootstrap-styles .align-self-md-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important;
  }
  .bootstrap-styles .align-self-md-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important;
  }
  .bootstrap-styles .order-md-first {
    -webkit-box-ordinal-group: 0 !important;
    -webkit-order: -1 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .bootstrap-styles .order-md-0 {
    -webkit-box-ordinal-group: 1 !important;
    -webkit-order: 0 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .bootstrap-styles .order-md-1 {
    -webkit-box-ordinal-group: 2 !important;
    -webkit-order: 1 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .bootstrap-styles .order-md-2 {
    -webkit-box-ordinal-group: 3 !important;
    -webkit-order: 2 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .bootstrap-styles .order-md-3 {
    -webkit-box-ordinal-group: 4 !important;
    -webkit-order: 3 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .bootstrap-styles .order-md-4 {
    -webkit-box-ordinal-group: 5 !important;
    -webkit-order: 4 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .bootstrap-styles .order-md-5 {
    -webkit-box-ordinal-group: 6 !important;
    -webkit-order: 5 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .bootstrap-styles .order-md-last {
    -webkit-box-ordinal-group: 7 !important;
    -webkit-order: 6 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .bootstrap-styles .m-md-0 {
    margin: 0 !important;
  }
  .bootstrap-styles .m-md-1 {
    margin: 0.25rem !important;
  }
  .bootstrap-styles .m-md-2 {
    margin: 0.5rem !important;
  }
  .bootstrap-styles .m-md-3 {
    margin: 1rem !important;
  }
  .bootstrap-styles .m-md-4 {
    margin: 1.5rem !important;
  }
  .bootstrap-styles .m-md-5 {
    margin: 3rem !important;
  }
  .bootstrap-styles .m-md-auto {
    margin: auto !important;
  }
  .bootstrap-styles .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .bootstrap-styles .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .bootstrap-styles .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .bootstrap-styles .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .bootstrap-styles .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .bootstrap-styles .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .bootstrap-styles .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .bootstrap-styles .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .bootstrap-styles .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-styles .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-styles .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .bootstrap-styles .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-styles .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .bootstrap-styles .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .bootstrap-styles .mt-md-0 {
    margin-top: 0 !important;
  }
  .bootstrap-styles .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap-styles .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap-styles .mt-md-3 {
    margin-top: 1rem !important;
  }
  .bootstrap-styles .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap-styles .mt-md-5 {
    margin-top: 3rem !important;
  }
  .bootstrap-styles .mt-md-auto {
    margin-top: auto !important;
  }
  .bootstrap-styles .me-md-0 {
    margin-right: 0 !important;
  }
  .bootstrap-styles .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap-styles .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap-styles .me-md-3 {
    margin-right: 1rem !important;
  }
  .bootstrap-styles .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap-styles .me-md-5 {
    margin-right: 3rem !important;
  }
  .bootstrap-styles .me-md-auto {
    margin-right: auto !important;
  }
  .bootstrap-styles .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap-styles .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-styles .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-styles .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap-styles .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-styles .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap-styles .mb-md-auto {
    margin-bottom: auto !important;
  }
  .bootstrap-styles .ms-md-0 {
    margin-left: 0 !important;
  }
  .bootstrap-styles .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap-styles .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap-styles .ms-md-3 {
    margin-left: 1rem !important;
  }
  .bootstrap-styles .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap-styles .ms-md-5 {
    margin-left: 3rem !important;
  }
  .bootstrap-styles .ms-md-auto {
    margin-left: auto !important;
  }
  .bootstrap-styles .p-md-0 {
    padding: 0 !important;
  }
  .bootstrap-styles .p-md-1 {
    padding: 0.25rem !important;
  }
  .bootstrap-styles .p-md-2 {
    padding: 0.5rem !important;
  }
  .bootstrap-styles .p-md-3 {
    padding: 1rem !important;
  }
  .bootstrap-styles .p-md-4 {
    padding: 1.5rem !important;
  }
  .bootstrap-styles .p-md-5 {
    padding: 3rem !important;
  }
  .bootstrap-styles .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .bootstrap-styles .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .bootstrap-styles .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .bootstrap-styles .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .bootstrap-styles .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .bootstrap-styles .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .bootstrap-styles .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .bootstrap-styles .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-styles .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-styles .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .bootstrap-styles .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-styles .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .bootstrap-styles .pt-md-0 {
    padding-top: 0 !important;
  }
  .bootstrap-styles .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap-styles .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap-styles .pt-md-3 {
    padding-top: 1rem !important;
  }
  .bootstrap-styles .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap-styles .pt-md-5 {
    padding-top: 3rem !important;
  }
  .bootstrap-styles .pe-md-0 {
    padding-right: 0 !important;
  }
  .bootstrap-styles .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap-styles .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap-styles .pe-md-3 {
    padding-right: 1rem !important;
  }
  .bootstrap-styles .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap-styles .pe-md-5 {
    padding-right: 3rem !important;
  }
  .bootstrap-styles .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap-styles .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-styles .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-styles .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap-styles .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-styles .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap-styles .ps-md-0 {
    padding-left: 0 !important;
  }
  .bootstrap-styles .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap-styles .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap-styles .ps-md-3 {
    padding-left: 1rem !important;
  }
  .bootstrap-styles .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap-styles .ps-md-5 {
    padding-left: 3rem !important;
  }
  .bootstrap-styles .gap-md-0 {
    gap: 0 !important;
  }
  .bootstrap-styles .gap-md-1 {
    gap: 0.25rem !important;
  }
  .bootstrap-styles .gap-md-2 {
    gap: 0.5rem !important;
  }
  .bootstrap-styles .gap-md-3 {
    gap: 1rem !important;
  }
  .bootstrap-styles .gap-md-4 {
    gap: 1.5rem !important;
  }
  .bootstrap-styles .gap-md-5 {
    gap: 3rem !important;
  }
  .bootstrap-styles .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .bootstrap-styles .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .bootstrap-styles .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .bootstrap-styles .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .bootstrap-styles .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .bootstrap-styles .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .bootstrap-styles .column-gap-md-0 {
    -moz-column-gap: 0 !important;
    -webkit-column-gap: 0 !important;
            column-gap: 0 !important;
  }
  .bootstrap-styles .column-gap-md-1 {
    -moz-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }
  .bootstrap-styles .column-gap-md-2 {
    -moz-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }
  .bootstrap-styles .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }
  .bootstrap-styles .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }
  .bootstrap-styles .column-gap-md-5 {
    -moz-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }
  .bootstrap-styles .text-md-start {
    text-align: left !important;
  }
  .bootstrap-styles .text-md-end {
    text-align: right !important;
  }
  .bootstrap-styles .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .bootstrap-styles .float-lg-start {
    float: left !important;
  }
  .bootstrap-styles .float-lg-end {
    float: right !important;
  }
  .bootstrap-styles .float-lg-none {
    float: none !important;
  }
  .bootstrap-styles .object-fit-lg-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .bootstrap-styles .object-fit-lg-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .bootstrap-styles .object-fit-lg-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .bootstrap-styles .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .bootstrap-styles .object-fit-lg-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .bootstrap-styles .d-lg-inline {
    display: inline !important;
  }
  .bootstrap-styles .d-lg-inline-block {
    display: inline-block !important;
  }
  .bootstrap-styles .d-lg-block {
    display: block !important;
  }
  .bootstrap-styles .d-lg-grid {
    display: grid !important;
  }
  .bootstrap-styles .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .bootstrap-styles .d-lg-table {
    display: table !important;
  }
  .bootstrap-styles .d-lg-table-row {
    display: table-row !important;
  }
  .bootstrap-styles .d-lg-table-cell {
    display: table-cell !important;
  }
  .bootstrap-styles .d-lg-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .bootstrap-styles .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .bootstrap-styles .d-lg-none {
    display: none !important;
  }
  .bootstrap-styles .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .bootstrap-styles .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .bootstrap-styles .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .bootstrap-styles .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .bootstrap-styles .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .bootstrap-styles .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .bootstrap-styles .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .bootstrap-styles .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important;
  }
  .bootstrap-styles .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important;
  }
  .bootstrap-styles .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .bootstrap-styles .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .bootstrap-styles .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .bootstrap-styles .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .bootstrap-styles .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .bootstrap-styles .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .bootstrap-styles .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .bootstrap-styles .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important;
  }
  .bootstrap-styles .justify-content-lg-evenly {
    -webkit-box-pack: space-evenly !important;
    -webkit-justify-content: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .bootstrap-styles .align-items-lg-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .bootstrap-styles .align-items-lg-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .bootstrap-styles .align-items-lg-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .bootstrap-styles .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .bootstrap-styles .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .bootstrap-styles .align-content-lg-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important;
  }
  .bootstrap-styles .align-content-lg-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important;
  }
  .bootstrap-styles .align-content-lg-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important;
  }
  .bootstrap-styles .align-content-lg-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important;
  }
  .bootstrap-styles .align-content-lg-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important;
  }
  .bootstrap-styles .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important;
  }
  .bootstrap-styles .align-self-lg-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important;
  }
  .bootstrap-styles .align-self-lg-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important;
  }
  .bootstrap-styles .align-self-lg-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important;
  }
  .bootstrap-styles .align-self-lg-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important;
  }
  .bootstrap-styles .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important;
  }
  .bootstrap-styles .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important;
  }
  .bootstrap-styles .order-lg-first {
    -webkit-box-ordinal-group: 0 !important;
    -webkit-order: -1 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .bootstrap-styles .order-lg-0 {
    -webkit-box-ordinal-group: 1 !important;
    -webkit-order: 0 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .bootstrap-styles .order-lg-1 {
    -webkit-box-ordinal-group: 2 !important;
    -webkit-order: 1 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .bootstrap-styles .order-lg-2 {
    -webkit-box-ordinal-group: 3 !important;
    -webkit-order: 2 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .bootstrap-styles .order-lg-3 {
    -webkit-box-ordinal-group: 4 !important;
    -webkit-order: 3 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .bootstrap-styles .order-lg-4 {
    -webkit-box-ordinal-group: 5 !important;
    -webkit-order: 4 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .bootstrap-styles .order-lg-5 {
    -webkit-box-ordinal-group: 6 !important;
    -webkit-order: 5 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .bootstrap-styles .order-lg-last {
    -webkit-box-ordinal-group: 7 !important;
    -webkit-order: 6 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .bootstrap-styles .m-lg-0 {
    margin: 0 !important;
  }
  .bootstrap-styles .m-lg-1 {
    margin: 0.25rem !important;
  }
  .bootstrap-styles .m-lg-2 {
    margin: 0.5rem !important;
  }
  .bootstrap-styles .m-lg-3 {
    margin: 1rem !important;
  }
  .bootstrap-styles .m-lg-4 {
    margin: 1.5rem !important;
  }
  .bootstrap-styles .m-lg-5 {
    margin: 3rem !important;
  }
  .bootstrap-styles .m-lg-auto {
    margin: auto !important;
  }
  .bootstrap-styles .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .bootstrap-styles .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .bootstrap-styles .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .bootstrap-styles .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .bootstrap-styles .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .bootstrap-styles .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .bootstrap-styles .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .bootstrap-styles .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .bootstrap-styles .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-styles .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-styles .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .bootstrap-styles .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-styles .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .bootstrap-styles .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .bootstrap-styles .mt-lg-0 {
    margin-top: 0 !important;
  }
  .bootstrap-styles .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap-styles .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap-styles .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .bootstrap-styles .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap-styles .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .bootstrap-styles .mt-lg-auto {
    margin-top: auto !important;
  }
  .bootstrap-styles .me-lg-0 {
    margin-right: 0 !important;
  }
  .bootstrap-styles .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap-styles .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap-styles .me-lg-3 {
    margin-right: 1rem !important;
  }
  .bootstrap-styles .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap-styles .me-lg-5 {
    margin-right: 3rem !important;
  }
  .bootstrap-styles .me-lg-auto {
    margin-right: auto !important;
  }
  .bootstrap-styles .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap-styles .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-styles .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-styles .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap-styles .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-styles .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap-styles .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .bootstrap-styles .ms-lg-0 {
    margin-left: 0 !important;
  }
  .bootstrap-styles .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap-styles .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap-styles .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .bootstrap-styles .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap-styles .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .bootstrap-styles .ms-lg-auto {
    margin-left: auto !important;
  }
  .bootstrap-styles .p-lg-0 {
    padding: 0 !important;
  }
  .bootstrap-styles .p-lg-1 {
    padding: 0.25rem !important;
  }
  .bootstrap-styles .p-lg-2 {
    padding: 0.5rem !important;
  }
  .bootstrap-styles .p-lg-3 {
    padding: 1rem !important;
  }
  .bootstrap-styles .p-lg-4 {
    padding: 1.5rem !important;
  }
  .bootstrap-styles .p-lg-5 {
    padding: 3rem !important;
  }
  .bootstrap-styles .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .bootstrap-styles .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .bootstrap-styles .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .bootstrap-styles .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .bootstrap-styles .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .bootstrap-styles .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .bootstrap-styles .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .bootstrap-styles .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-styles .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-styles .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .bootstrap-styles .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-styles .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .bootstrap-styles .pt-lg-0 {
    padding-top: 0 !important;
  }
  .bootstrap-styles .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap-styles .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap-styles .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .bootstrap-styles .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap-styles .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .bootstrap-styles .pe-lg-0 {
    padding-right: 0 !important;
  }
  .bootstrap-styles .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap-styles .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap-styles .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .bootstrap-styles .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap-styles .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .bootstrap-styles .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap-styles .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-styles .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-styles .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap-styles .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-styles .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap-styles .ps-lg-0 {
    padding-left: 0 !important;
  }
  .bootstrap-styles .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap-styles .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap-styles .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .bootstrap-styles .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap-styles .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .bootstrap-styles .gap-lg-0 {
    gap: 0 !important;
  }
  .bootstrap-styles .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .bootstrap-styles .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .bootstrap-styles .gap-lg-3 {
    gap: 1rem !important;
  }
  .bootstrap-styles .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .bootstrap-styles .gap-lg-5 {
    gap: 3rem !important;
  }
  .bootstrap-styles .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .bootstrap-styles .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .bootstrap-styles .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .bootstrap-styles .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .bootstrap-styles .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .bootstrap-styles .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .bootstrap-styles .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
    -webkit-column-gap: 0 !important;
            column-gap: 0 !important;
  }
  .bootstrap-styles .column-gap-lg-1 {
    -moz-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }
  .bootstrap-styles .column-gap-lg-2 {
    -moz-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }
  .bootstrap-styles .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }
  .bootstrap-styles .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }
  .bootstrap-styles .column-gap-lg-5 {
    -moz-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }
  .bootstrap-styles .text-lg-start {
    text-align: left !important;
  }
  .bootstrap-styles .text-lg-end {
    text-align: right !important;
  }
  .bootstrap-styles .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .bootstrap-styles .float-xl-start {
    float: left !important;
  }
  .bootstrap-styles .float-xl-end {
    float: right !important;
  }
  .bootstrap-styles .float-xl-none {
    float: none !important;
  }
  .bootstrap-styles .object-fit-xl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .bootstrap-styles .object-fit-xl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .bootstrap-styles .object-fit-xl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .bootstrap-styles .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .bootstrap-styles .object-fit-xl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .bootstrap-styles .d-xl-inline {
    display: inline !important;
  }
  .bootstrap-styles .d-xl-inline-block {
    display: inline-block !important;
  }
  .bootstrap-styles .d-xl-block {
    display: block !important;
  }
  .bootstrap-styles .d-xl-grid {
    display: grid !important;
  }
  .bootstrap-styles .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .bootstrap-styles .d-xl-table {
    display: table !important;
  }
  .bootstrap-styles .d-xl-table-row {
    display: table-row !important;
  }
  .bootstrap-styles .d-xl-table-cell {
    display: table-cell !important;
  }
  .bootstrap-styles .d-xl-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .bootstrap-styles .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .bootstrap-styles .d-xl-none {
    display: none !important;
  }
  .bootstrap-styles .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .bootstrap-styles .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .bootstrap-styles .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .bootstrap-styles .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .bootstrap-styles .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .bootstrap-styles .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .bootstrap-styles .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .bootstrap-styles .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important;
  }
  .bootstrap-styles .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important;
  }
  .bootstrap-styles .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .bootstrap-styles .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .bootstrap-styles .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .bootstrap-styles .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .bootstrap-styles .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .bootstrap-styles .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .bootstrap-styles .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .bootstrap-styles .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important;
  }
  .bootstrap-styles .justify-content-xl-evenly {
    -webkit-box-pack: space-evenly !important;
    -webkit-justify-content: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .bootstrap-styles .align-items-xl-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .bootstrap-styles .align-items-xl-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .bootstrap-styles .align-items-xl-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .bootstrap-styles .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .bootstrap-styles .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .bootstrap-styles .align-content-xl-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important;
  }
  .bootstrap-styles .align-content-xl-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important;
  }
  .bootstrap-styles .align-content-xl-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important;
  }
  .bootstrap-styles .align-content-xl-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important;
  }
  .bootstrap-styles .align-content-xl-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important;
  }
  .bootstrap-styles .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important;
  }
  .bootstrap-styles .align-self-xl-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important;
  }
  .bootstrap-styles .align-self-xl-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important;
  }
  .bootstrap-styles .align-self-xl-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important;
  }
  .bootstrap-styles .align-self-xl-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important;
  }
  .bootstrap-styles .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important;
  }
  .bootstrap-styles .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important;
  }
  .bootstrap-styles .order-xl-first {
    -webkit-box-ordinal-group: 0 !important;
    -webkit-order: -1 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .bootstrap-styles .order-xl-0 {
    -webkit-box-ordinal-group: 1 !important;
    -webkit-order: 0 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .bootstrap-styles .order-xl-1 {
    -webkit-box-ordinal-group: 2 !important;
    -webkit-order: 1 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .bootstrap-styles .order-xl-2 {
    -webkit-box-ordinal-group: 3 !important;
    -webkit-order: 2 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .bootstrap-styles .order-xl-3 {
    -webkit-box-ordinal-group: 4 !important;
    -webkit-order: 3 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .bootstrap-styles .order-xl-4 {
    -webkit-box-ordinal-group: 5 !important;
    -webkit-order: 4 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .bootstrap-styles .order-xl-5 {
    -webkit-box-ordinal-group: 6 !important;
    -webkit-order: 5 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .bootstrap-styles .order-xl-last {
    -webkit-box-ordinal-group: 7 !important;
    -webkit-order: 6 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .bootstrap-styles .m-xl-0 {
    margin: 0 !important;
  }
  .bootstrap-styles .m-xl-1 {
    margin: 0.25rem !important;
  }
  .bootstrap-styles .m-xl-2 {
    margin: 0.5rem !important;
  }
  .bootstrap-styles .m-xl-3 {
    margin: 1rem !important;
  }
  .bootstrap-styles .m-xl-4 {
    margin: 1.5rem !important;
  }
  .bootstrap-styles .m-xl-5 {
    margin: 3rem !important;
  }
  .bootstrap-styles .m-xl-auto {
    margin: auto !important;
  }
  .bootstrap-styles .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .bootstrap-styles .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .bootstrap-styles .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .bootstrap-styles .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .bootstrap-styles .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .bootstrap-styles .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .bootstrap-styles .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .bootstrap-styles .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .bootstrap-styles .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-styles .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-styles .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .bootstrap-styles .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-styles .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .bootstrap-styles .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .bootstrap-styles .mt-xl-0 {
    margin-top: 0 !important;
  }
  .bootstrap-styles .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap-styles .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap-styles .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .bootstrap-styles .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap-styles .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .bootstrap-styles .mt-xl-auto {
    margin-top: auto !important;
  }
  .bootstrap-styles .me-xl-0 {
    margin-right: 0 !important;
  }
  .bootstrap-styles .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap-styles .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap-styles .me-xl-3 {
    margin-right: 1rem !important;
  }
  .bootstrap-styles .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap-styles .me-xl-5 {
    margin-right: 3rem !important;
  }
  .bootstrap-styles .me-xl-auto {
    margin-right: auto !important;
  }
  .bootstrap-styles .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap-styles .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-styles .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-styles .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap-styles .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-styles .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap-styles .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .bootstrap-styles .ms-xl-0 {
    margin-left: 0 !important;
  }
  .bootstrap-styles .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap-styles .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap-styles .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .bootstrap-styles .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap-styles .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .bootstrap-styles .ms-xl-auto {
    margin-left: auto !important;
  }
  .bootstrap-styles .p-xl-0 {
    padding: 0 !important;
  }
  .bootstrap-styles .p-xl-1 {
    padding: 0.25rem !important;
  }
  .bootstrap-styles .p-xl-2 {
    padding: 0.5rem !important;
  }
  .bootstrap-styles .p-xl-3 {
    padding: 1rem !important;
  }
  .bootstrap-styles .p-xl-4 {
    padding: 1.5rem !important;
  }
  .bootstrap-styles .p-xl-5 {
    padding: 3rem !important;
  }
  .bootstrap-styles .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .bootstrap-styles .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .bootstrap-styles .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .bootstrap-styles .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .bootstrap-styles .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .bootstrap-styles .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .bootstrap-styles .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .bootstrap-styles .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-styles .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-styles .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .bootstrap-styles .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-styles .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .bootstrap-styles .pt-xl-0 {
    padding-top: 0 !important;
  }
  .bootstrap-styles .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap-styles .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap-styles .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .bootstrap-styles .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap-styles .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .bootstrap-styles .pe-xl-0 {
    padding-right: 0 !important;
  }
  .bootstrap-styles .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap-styles .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap-styles .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .bootstrap-styles .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap-styles .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .bootstrap-styles .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap-styles .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-styles .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-styles .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap-styles .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-styles .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap-styles .ps-xl-0 {
    padding-left: 0 !important;
  }
  .bootstrap-styles .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap-styles .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap-styles .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .bootstrap-styles .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap-styles .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .bootstrap-styles .gap-xl-0 {
    gap: 0 !important;
  }
  .bootstrap-styles .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .bootstrap-styles .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .bootstrap-styles .gap-xl-3 {
    gap: 1rem !important;
  }
  .bootstrap-styles .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .bootstrap-styles .gap-xl-5 {
    gap: 3rem !important;
  }
  .bootstrap-styles .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .bootstrap-styles .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .bootstrap-styles .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .bootstrap-styles .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .bootstrap-styles .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .bootstrap-styles .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .bootstrap-styles .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
    -webkit-column-gap: 0 !important;
            column-gap: 0 !important;
  }
  .bootstrap-styles .column-gap-xl-1 {
    -moz-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }
  .bootstrap-styles .column-gap-xl-2 {
    -moz-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }
  .bootstrap-styles .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }
  .bootstrap-styles .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }
  .bootstrap-styles .column-gap-xl-5 {
    -moz-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }
  .bootstrap-styles .text-xl-start {
    text-align: left !important;
  }
  .bootstrap-styles .text-xl-end {
    text-align: right !important;
  }
  .bootstrap-styles .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .bootstrap-styles .float-xxl-start {
    float: left !important;
  }
  .bootstrap-styles .float-xxl-end {
    float: right !important;
  }
  .bootstrap-styles .float-xxl-none {
    float: none !important;
  }
  .bootstrap-styles .object-fit-xxl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .bootstrap-styles .object-fit-xxl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .bootstrap-styles .object-fit-xxl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .bootstrap-styles .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .bootstrap-styles .object-fit-xxl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .bootstrap-styles .d-xxl-inline {
    display: inline !important;
  }
  .bootstrap-styles .d-xxl-inline-block {
    display: inline-block !important;
  }
  .bootstrap-styles .d-xxl-block {
    display: block !important;
  }
  .bootstrap-styles .d-xxl-grid {
    display: grid !important;
  }
  .bootstrap-styles .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .bootstrap-styles .d-xxl-table {
    display: table !important;
  }
  .bootstrap-styles .d-xxl-table-row {
    display: table-row !important;
  }
  .bootstrap-styles .d-xxl-table-cell {
    display: table-cell !important;
  }
  .bootstrap-styles .d-xxl-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .bootstrap-styles .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .bootstrap-styles .d-xxl-none {
    display: none !important;
  }
  .bootstrap-styles .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .bootstrap-styles .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .bootstrap-styles .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .bootstrap-styles .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .bootstrap-styles .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .bootstrap-styles .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .bootstrap-styles .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .bootstrap-styles .flex-xxl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important;
  }
  .bootstrap-styles .flex-xxl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important;
  }
  .bootstrap-styles .flex-xxl-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .bootstrap-styles .flex-xxl-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .bootstrap-styles .flex-xxl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .bootstrap-styles .justify-content-xxl-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .bootstrap-styles .justify-content-xxl-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .bootstrap-styles .justify-content-xxl-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .bootstrap-styles .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .bootstrap-styles .justify-content-xxl-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important;
  }
  .bootstrap-styles .justify-content-xxl-evenly {
    -webkit-box-pack: space-evenly !important;
    -webkit-justify-content: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .bootstrap-styles .align-items-xxl-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .bootstrap-styles .align-items-xxl-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .bootstrap-styles .align-items-xxl-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .bootstrap-styles .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .bootstrap-styles .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .bootstrap-styles .align-content-xxl-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important;
  }
  .bootstrap-styles .align-content-xxl-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important;
  }
  .bootstrap-styles .align-content-xxl-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important;
  }
  .bootstrap-styles .align-content-xxl-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important;
  }
  .bootstrap-styles .align-content-xxl-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important;
  }
  .bootstrap-styles .align-content-xxl-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important;
  }
  .bootstrap-styles .align-self-xxl-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important;
  }
  .bootstrap-styles .align-self-xxl-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important;
  }
  .bootstrap-styles .align-self-xxl-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important;
  }
  .bootstrap-styles .align-self-xxl-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important;
  }
  .bootstrap-styles .align-self-xxl-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important;
  }
  .bootstrap-styles .align-self-xxl-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important;
  }
  .bootstrap-styles .order-xxl-first {
    -webkit-box-ordinal-group: 0 !important;
    -webkit-order: -1 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .bootstrap-styles .order-xxl-0 {
    -webkit-box-ordinal-group: 1 !important;
    -webkit-order: 0 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .bootstrap-styles .order-xxl-1 {
    -webkit-box-ordinal-group: 2 !important;
    -webkit-order: 1 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .bootstrap-styles .order-xxl-2 {
    -webkit-box-ordinal-group: 3 !important;
    -webkit-order: 2 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .bootstrap-styles .order-xxl-3 {
    -webkit-box-ordinal-group: 4 !important;
    -webkit-order: 3 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .bootstrap-styles .order-xxl-4 {
    -webkit-box-ordinal-group: 5 !important;
    -webkit-order: 4 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .bootstrap-styles .order-xxl-5 {
    -webkit-box-ordinal-group: 6 !important;
    -webkit-order: 5 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .bootstrap-styles .order-xxl-last {
    -webkit-box-ordinal-group: 7 !important;
    -webkit-order: 6 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .bootstrap-styles .m-xxl-0 {
    margin: 0 !important;
  }
  .bootstrap-styles .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .bootstrap-styles .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .bootstrap-styles .m-xxl-3 {
    margin: 1rem !important;
  }
  .bootstrap-styles .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .bootstrap-styles .m-xxl-5 {
    margin: 3rem !important;
  }
  .bootstrap-styles .m-xxl-auto {
    margin: auto !important;
  }
  .bootstrap-styles .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .bootstrap-styles .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .bootstrap-styles .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .bootstrap-styles .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .bootstrap-styles .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .bootstrap-styles .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .bootstrap-styles .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .bootstrap-styles .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .bootstrap-styles .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-styles .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-styles .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .bootstrap-styles .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-styles .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .bootstrap-styles .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .bootstrap-styles .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .bootstrap-styles .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap-styles .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap-styles .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .bootstrap-styles .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap-styles .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .bootstrap-styles .mt-xxl-auto {
    margin-top: auto !important;
  }
  .bootstrap-styles .me-xxl-0 {
    margin-right: 0 !important;
  }
  .bootstrap-styles .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap-styles .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap-styles .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .bootstrap-styles .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap-styles .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .bootstrap-styles .me-xxl-auto {
    margin-right: auto !important;
  }
  .bootstrap-styles .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap-styles .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-styles .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-styles .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap-styles .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-styles .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap-styles .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .bootstrap-styles .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .bootstrap-styles .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap-styles .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap-styles .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .bootstrap-styles .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap-styles .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .bootstrap-styles .ms-xxl-auto {
    margin-left: auto !important;
  }
  .bootstrap-styles .p-xxl-0 {
    padding: 0 !important;
  }
  .bootstrap-styles .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .bootstrap-styles .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .bootstrap-styles .p-xxl-3 {
    padding: 1rem !important;
  }
  .bootstrap-styles .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .bootstrap-styles .p-xxl-5 {
    padding: 3rem !important;
  }
  .bootstrap-styles .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .bootstrap-styles .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .bootstrap-styles .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .bootstrap-styles .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .bootstrap-styles .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .bootstrap-styles .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .bootstrap-styles .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .bootstrap-styles .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-styles .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-styles .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .bootstrap-styles .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-styles .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .bootstrap-styles .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .bootstrap-styles .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap-styles .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap-styles .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .bootstrap-styles .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap-styles .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .bootstrap-styles .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .bootstrap-styles .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap-styles .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap-styles .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .bootstrap-styles .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap-styles .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .bootstrap-styles .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap-styles .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-styles .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-styles .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap-styles .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-styles .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap-styles .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .bootstrap-styles .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap-styles .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap-styles .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .bootstrap-styles .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap-styles .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .bootstrap-styles .gap-xxl-0 {
    gap: 0 !important;
  }
  .bootstrap-styles .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .bootstrap-styles .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .bootstrap-styles .gap-xxl-3 {
    gap: 1rem !important;
  }
  .bootstrap-styles .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .bootstrap-styles .gap-xxl-5 {
    gap: 3rem !important;
  }
  .bootstrap-styles .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .bootstrap-styles .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .bootstrap-styles .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .bootstrap-styles .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .bootstrap-styles .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .bootstrap-styles .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .bootstrap-styles .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
    -webkit-column-gap: 0 !important;
            column-gap: 0 !important;
  }
  .bootstrap-styles .column-gap-xxl-1 {
    -moz-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }
  .bootstrap-styles .column-gap-xxl-2 {
    -moz-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }
  .bootstrap-styles .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }
  .bootstrap-styles .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }
  .bootstrap-styles .column-gap-xxl-5 {
    -moz-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }
  .bootstrap-styles .text-xxl-start {
    text-align: left !important;
  }
  .bootstrap-styles .text-xxl-end {
    text-align: right !important;
  }
  .bootstrap-styles .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .bootstrap-styles .fs-1 {
    font-size: 2.5rem !important;
  }
  .bootstrap-styles .fs-2 {
    font-size: 2rem !important;
  }
  .bootstrap-styles .fs-3 {
    font-size: 1.75rem !important;
  }
  .bootstrap-styles .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .bootstrap-styles .d-print-inline {
    display: inline !important;
  }
  .bootstrap-styles .d-print-inline-block {
    display: inline-block !important;
  }
  .bootstrap-styles .d-print-block {
    display: block !important;
  }
  .bootstrap-styles .d-print-grid {
    display: grid !important;
  }
  .bootstrap-styles .d-print-inline-grid {
    display: inline-grid !important;
  }
  .bootstrap-styles .d-print-table {
    display: table !important;
  }
  .bootstrap-styles .d-print-table-row {
    display: table-row !important;
  }
  .bootstrap-styles .d-print-table-cell {
    display: table-cell !important;
  }
  .bootstrap-styles .d-print-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .bootstrap-styles .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .bootstrap-styles .d-print-none {
    display: none !important;
  }
}